/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import BikeBooking from "../../types/model/BikeBooking";
import { Actions, Types } from "../actions/bikeBookings";

const initialState: {
    [key: string]: BikeBooking[];
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function bikeBookings(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BIKE_BOOKINGS: {
            const payload = action.payload as ActionValueTypes<Types.SET_BIKE_BOOKINGS>;
            const { bookingId, bikeBookings: bikeBookingsList } = payload!;

            return {
                ...state,
                [bookingId]: bikeBookingsList,
            };
        }
        default: {
            return state;
        }
    }
}
