/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactElement } from "react";
import * as Yup from "yup";
import Button from "../../widgets/button/Button";
import Input from "../../widgets/input/Input";
import styles from "./Login.module.scss";

const FormValidation = Yup.object<FormValues>({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string().required("Required"),
});

type FormValues = {
    email: string;
    password: string;
};

export default function Login(): ReactElement {
    const onSubmit = async ({ email, password }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        try {
            await window.firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (err) {
            formikHelpers.setFieldError("email", err.message);
        }
    };

    return (
        <div className={styles.login}>
            <Card className={styles.login_card}>
                <Formik<FormValues>
                    validationSchema={FormValidation}
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className={styles.login_form}>
                            <Typography>Login</Typography>
                            <Input name={"email"} label={"Email"} placeholder={"hello@openroad.nz"} />
                            <Input name={"password"} label={"Password"} placeholder={"******"} type={"password"} />
                            <Button type={"submit"} loading={isSubmitting}>Login</Button>
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    );
}
