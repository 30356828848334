/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { updateBikeGroup } from "../../../../../../../redux/actions/bikes";
import BikeGroup, { BikeGroupStatus } from "../../../../../../../types/model/BikeGroup";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Gallery from "../../../../../../widgets/gallery/Gallery";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./BikeGroupDetailsCard.module.scss";

type Props = {
    bikeGroupId: string;
};

type FormValues = {} & Omit<BikeGroup, "id">;

export default function BikeGroupDetailsCard(props: Props) {
    const { bikeGroupId } = props;

    const bikeGroup = useSelector((state) => state.bikeGroups[bikeGroupId]);

    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();

    const onSubmit = async (values: FormValues) => {
        try {
            setError(null);
            await dispatch(updateBikeGroup(bikeGroup.id, values));
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Card className={styles.details_card}>
            <Formik initialValues={bikeGroup || {}} onSubmit={onSubmit} enableReinitialize>
                {({ isValid, dirty, values, setFieldValue, isSubmitting }: FormikProps<FormValues>) => (
                    <Form>
                        <CardHeader
                            title={bikeGroup?.name || "Loading..."}
                            action={(
                                <Button type={"submit"} disabled={!isValid || !dirty}>
                                    Save
                                </Button>
                              )}
                        />
                        <ErrorBlock error={error} />

                        <div className={styles.info_card_block}>
                            <FormRow>
                                <Input
                                    name={"status"}
                                    label={"Status"}
                                    options={[
                                        {
                                            label: "Active",
                                            value: BikeGroupStatus.ACTIVE,
                                        },
                                        {
                                            label: "Inactive",
                                            value: BikeGroupStatus.INACTIVE,
                                        },
                                        {
                                            label: "Deleted",
                                            value: BikeGroupStatus.DELETED,
                                        },
                                    ]}
                                />
                                <div />
                            </FormRow>
                            <FormRow>
                                <Input name={"name"} label={"Name"} placeholder={"Triumph Tiger 800xr"} />
                            </FormRow>
                            <FormRow>
                                <Input name={"pricePerDay3To6Low"} label={"3 - 6 Days Low"} money />
                                <Input name={"pricePerDay7To20Low"} label={"7 - 20 Days Low"} money />
                                <Input name={"pricePerDay21PlusLow"} label={"21+ Days Low"} money />
                            </FormRow>
                            <FormRow>
                                <Input name={"pricePerDay3To6High"} label={"3 - 6 Days High"} money />
                                <Input name={"pricePerDay7To20High"} label={"7 - 20 Days High"} money />
                                <Input name={"pricePerDay21PlusHigh"} label={"21+ Days High"} money />
                            </FormRow>
                            <FormRow>
                                <Input name={"cc"} label={"CC"} type={"number"} />
                                <Input name={"power"} label={"Power"} type={"number"} />
                            </FormRow>
                            <FormRow>
                                <Input name={"torqueNm"} label={"Torque Nm"} type={"number"} />
                                <Input name={"torqueRevs"} label={"Torque Revs"} type={"number"} />
                            </FormRow>
                            <FormRow>
                                <Input
                                    name={"fuelCapacity"}
                                    label={"Fuel Capacity"}
                                    type={"number"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position={"end"}>L</InputAdornment>,
                                    }}
                                />
                                <Input
                                    name={"weight"}
                                    label={"Weight"}
                                    type={"number"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position={"end"}>KG</InputAdornment>,
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <Input name={"windProtection"} label={"Wind Protection"} />
                                <Input
                                    name={"seatHeight"}
                                    label={"Seat Height"}
                                    type={"number"}
                                    InputProps={{
                                        endAdornment: <InputAdornment position={"end"}>mm</InputAdornment>,
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <Input name={"luggageSystem"} label={"Luggage System"} />
                                <Input name={"finalDrive"} label={"Final Drive"} />
                            </FormRow>

                            <FormRow>
                                <Input name={"engineConfiguration"} label={"Engine Configuration"} />
                            </FormRow>
                            <FormRow>
                                <Input name={"otherFeatures"} label={"Other Features"} />
                            </FormRow>
                        </div>

                        <ReactQuill value={values.description} onChange={(value) => setFieldValue("description", value)} />

                        <Gallery name={"images"} />
                        {isSubmitting && <Spinner overlay />}
                    </Form>
                )}
            </Formik>
        </Card>
    );
}
