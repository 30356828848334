/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 3/12/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { useSelector as useReduxSelector } from "react-redux";
import { ApplicationState } from "../redux/reducers/root";

export default function useSelector<T>(selector: (state: ApplicationState) => T, equalityFn?: (left: T, right: T) => boolean) {
    return useReduxSelector<ApplicationState, T>(selector, equalityFn);
}
