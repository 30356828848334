/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classNames from "classnames";
import React from "react";
import styles from "./Spinner.module.scss";

export type SpinnerProps = {
    className?: string;
    containerClassName?: string;
    fullscreen?: boolean;
    overlay?: boolean;
    noBackground?: boolean;
    center?: boolean;
    vCenter?: boolean;
    hCenter?: boolean;

    small?: boolean;
    large?: boolean;
    white?: boolean;
};

export default function Spinner(props: SpinnerProps) {
    const { fullscreen, overlay, center, vCenter, hCenter, noBackground, small, large, white, ...otherProps } = props;

    const className = classNames(styles.spinner, {
        [styles.small]: small,
        [styles.large]: large,
        [styles.white]: white,
    });

    const spinner = (
        <svg viewBox={"0 0 24 24"} xmlns={"http://www.w3.org/2000/svg"} {...otherProps} className={className}>
            <ellipse cx={"12"} cy={"12"} rx={"10"} ry={"10"} className={styles.spinner_ellipse} />
        </svg>
    );

    if (fullscreen || overlay || center || vCenter || hCenter) {
        const containerClassName = classNames(styles.container, {
            [styles.fullscreen]: fullscreen,
            [styles.overlay]: overlay,
            [styles.hCenter]: hCenter,
            [styles.vCenter]: vCenter,
            [styles.center]: center,
            [styles.noBackground]: noBackground,
        });

        return <div className={containerClassName}>{spinner}</div>;
    }

    return spinner;
}
