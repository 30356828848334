/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {DateTime} from "luxon";
import TourDate from "../../../types/model/TourDate";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getTourDates(tourId: string): Promise<TourDate[]> {
    return get(`/v1/tours/${tourId}/tour-dates`);
}

function createTourDate(tourId: string, tourDate: Omit<TourDate, "id" | "tour" | "status">): Promise<TourDate> {
    return post(`/v1/tours/${tourId}/tour-dates`, {
        ...tourDate,
        startDate: DateTime.fromFormat(tourDate.startDate, "yyyy-MM-dd").toISO(),
        endDate:DateTime.fromFormat(tourDate.endDate, "yyyy-MM-dd").toISO(),
    });
}

function updateTourDate(tourDateId: string, tourDate: Omit<TourDate, "id" | "tour">): Promise<TourDate> {
    return put(`/v1/tour-dates/${tourDateId}`, {
        ...tourDate,
        startDate: DateTime.fromFormat(tourDate.startDate, "yyyy-MM-dd").toISO(),
        endDate:DateTime.fromFormat(tourDate.endDate, "yyyy-MM-dd").toISO(),
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deleteTourDate(tourDateId: string, tourId: string): Promise<boolean> {
    return del(`/v1/tour-dates/${tourDateId}`);
}

const TourDatesAPI = {
    getTourDates,
    createTourDate,
    updateTourDate,
    deleteTourDate,
};

export default TourDatesAPI;
