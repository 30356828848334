/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum BikeStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
}

export default interface Bike {
    id: string;
    numberPlate: string;
    regoExpiry: string;
    cofExpiry: string;
    serviceExpiry: string;
    serviceMileage: number;
    currentMileage: number;
    bikeGroup: string;
    status: BikeStatus;
}
