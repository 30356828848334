/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import TourDate from "../../types/model/TourDate";
import { Actions, Types } from "../actions/tourDates";

const initialState: {
    [key: string]: {
        [key: string]: TourDate;
    };
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function tourDates(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_TOUR_DATE: {
            const payload = action.payload as ActionValueTypes<Types.SET_TOUR_DATE>;
            const { tourId, tourDate } = payload!;

            return {
                ...state,
                [tourId]: {
                    ...(state[tourId] || {}),
                    [tourDate.id]: tourDate,
                },
            };
        }
        case Types.SET_TOUR_DATES: {
            const payload = action.payload as ActionValueTypes<Types.SET_TOUR_DATES>;
            const { tourId, tourDates: tourDatesList } = payload!;

            const newState = {
                ...state,
            };

            tourDatesList.forEach((tourDate) => {
                newState[tourId] = {
                    ...(newState[tourId] || {}),
                    [tourDate.id]: tourDate,
                };
            });

            return newState;
        }
        case Types.REMOVE_TOUR_DATE: {
            const payload = action.payload as ActionValueTypes<Types.REMOVE_TOUR_DATE>;
            const { tourId, tourDateId } = payload!;

            const tour = {
                ...(state[tourId] || {}),
            };

            delete tour[tourDateId];

            return {
                ...state,
                [tourId]: tour,
            };
        }

        default: {
            return state;
        }
    }
}
