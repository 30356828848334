import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form as FormikForm, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { PropsWithChildren, Ref, useImperativeHandle, useState } from "react";
import Button from "../button/Button";
import ErrorBlock from "../errorBlock/ErrorBlock";
import styles from "./Dialog.module.scss";

/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export type DialogProps<FormValues> = PropsWithChildren<{
    dialogRef: Ref<DialogRef>;
    className?: string;

    updating?: boolean;
    createAction: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => Promise<any>;
    updateAction?: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => Promise<any>;
    deleteAction?: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => Promise<any>;

    validationSchema: any;
    initialValues: FormValues;
    title?: string;

    onClose?: () => void;
}>;

export type DialogRef = {
    show: () => void;
    hide: () => void;
};

type FormProps<FormValues extends FormikValues = FormikValues> = DialogProps<FormValues> &
    FormikProps<FormValues> & {
        shown: boolean;
        setShown: (bool: boolean) => void;
        deleting: boolean;
        setIsDeleting: (bool: boolean) => void;
        error: string | null;
        setError: (error: string | null) => void;
    };

function Form<FormValues extends FormikValues = FormikValues>(props: FormProps<FormValues>) {
    const {
        children,
        title,
        updating,
        deleteAction,
        isSubmitting,
        handleReset,
        shown,
        setShown,
        deleting,
        setIsDeleting,
        error,
        setError,
        isValid,
    } = props;

    const createUpdateText = updating ? "Update" : "Create";
    const createUpdateDeleteText = deleting ? "Confirm" : createUpdateText;

    const onDeletePress = () => {
        setIsDeleting(true);
    };

    const onClose = () => {
        handleReset();
        setShown(false);
        setTimeout(() => {
            setIsDeleting(false);
            setError(null);
        }, 125);
        props.onClose?.();
    };

    const onCancelPress = () => {
        handleReset();
        if (deleting) {
            setIsDeleting(false);
        } else {
            onClose();
        }
    };

    return (
        <MuiDialog
            className={styles.dialog}
            open={shown}
            onClose={onClose}
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}>
            <FormikForm>
                <DialogTitle className={styles.title}>{deleting ? "Confirm Delete" : title}</DialogTitle>
                <DialogContent className={styles.content}>
                    {deleting ? <span>Are you sure this cannot be undone?</span> : children}
                    <ErrorBlock error={error} />
                </DialogContent>
                <DialogActions className={styles.buttons}>
                    {updating && deleteAction && !deleting ? (
                        <Button onClick={onDeletePress} plain disabled={isSubmitting}>
                            Delete
                        </Button>
                    ) : (
                        <div />
                    )}
                    <div className={styles.buttonsRight}>
                        <Button onClick={onCancelPress} plain disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button type={"submit"} disabled={!isValid} loading={isSubmitting} red={deleting}>
                            {createUpdateDeleteText}
                        </Button>
                    </div>
                </DialogActions>
            </FormikForm>
        </MuiDialog>
    );
}

export default function Dialog<FormValues extends FormikValues = FormikValues>(props: DialogProps<FormValues>) {
    const { dialogRef, validationSchema, initialValues, updating, createAction, updateAction, deleteAction } = props;

    const [shown, setShown] = useState(false);
    const [deleting, setIsDeleting] = useState(false);
    const [error, setError] = useState<null | string>(null);

    useImperativeHandle(dialogRef, () => ({
        show: () => {
            setShown(true);
        },
        hide: () => {
            setShown(false);
        },
    }));

    const onClose = () => {
        setShown(false);
        setTimeout(() => {
            setIsDeleting(false);
            setError(null);
        }, 165);
        props.onClose?.();
    };

    const onSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        try {
            if (deleting) {
                if (deleteAction) {
                    await deleteAction(values, formikHelpers);
                }
            } else if (updating) {
                if (updateAction) {
                    await updateAction(values, formikHelpers);
                }
            } else {
                await createAction(values, formikHelpers);
            }
            onClose();
        } catch (e) {
            setError(e.message);
        }
    };

    return (
        <Formik<FormValues>
            onSubmit={onSubmit}
            validationSchema={!deleting && validationSchema}
            enableReinitialize
            initialValues={initialValues}>
            {(formikProps) => (
                <Form
                    {...formikProps}
                    {...props}
                    shown={shown}
                    setShown={setShown}
                    deleting={deleting}
                    setIsDeleting={setIsDeleting}
                    error={error}
                    setError={setError}
                />
            )}
        </Formik>
    );
}
