import * as firebase from "firebase";
import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import useAsyncCall from "../hooks/useAsyncCall";
import useLoggedInState from "../hooks/useLoggedInState";
import useMountEffect from "../hooks/useMountEffect";
import { getBikeGroups } from "../redux/actions/bikes";
import { getTours } from "../redux/actions/tours";
import { getUser } from "../redux/actions/user";
import Nullable from "../types/Nullable";
import Dashboard from "./routes/dashboard/Dashboard";
import Login from "./routes/login/Login";
import Spinner from "./widgets/spinner/Spinner";

export default function Main() {
    const asyncCall = useAsyncCall();
    const [loading, setLoading] = useState(true);
    const [isLoggedIn] = useLoggedInState();

    const onAuthStateChanged = async (firebaseUser: Nullable<firebase.User>) => {
        console.log("FIREBASE onAuthStateChanged: ", firebaseUser);
        if (firebaseUser) {
            try {
                const actions = {
                    user: getUser(),
                    tours: getTours(),
                    bikeGroups: getBikeGroups(),
                };
                await asyncCall(actions);
            } catch (e) {
                console.log("onAuthStateChanged ERROR:", e);
            }
        }

        setLoading(false);
    };

    useMountEffect(() => {
        window.firebase.auth().onAuthStateChanged(onAuthStateChanged);
    });

    if (loading) {
        return <Spinner center />;
    }

    return <Router>{!isLoggedIn ? <Route path={"*"} component={Login} /> : <Route path={"*"} component={Dashboard} />}</Router>;
}
