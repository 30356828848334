/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import MaterialTable, { Column } from "material-table";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { createBooking, getBookings } from "../../../../../redux/actions/bookings";
import Booking, { BookingType } from "../../../../../types/model/Booking";
// eslint-disable-next-line import/no-cycle
import { currency } from "../../../../../util/columnFormatters";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import FormRow from "../../../../widgets/formRow/FormRow";
import Input from "../../../../widgets/input/Input";
import Spinner from "../../../../widgets/spinner/Spinner";
import styles from "../../Dashboard.module.scss";

const columns: Column<Booking>[] = [
    {
        title: "Booking Name",
        field: "name",
    },
    {
        title: "Amount",
        field: "amount",
        render: currency("amount"),
    },
    {
        title: "Deposit Amount",
        field: "depositAmount",
        render: currency("depositAmount"),
    },
    {
        title: "Deposit Paid",
        field: "depositPaid",
        render: (booking) => (booking.depositPaid ? "Yes" : "No"),
    },
    {
        title: "Amount Paid",
        field: "amountPaid",
        render: currency("amountPaid"),
    },
    {
        title: "Type",
        field: "type",
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Pick<Booking, "type" | "name" | "email">;

const validationSchema = Yup.object<FormValues>({
    type: Yup.mixed()
        .oneOf(Object.values(BookingType))
        .required("Required"),
    name: Yup.string().required("Required"),
    email: Yup.string()
        .email("Invalid Email")
        .required("Required")
});

export default function Bookings() {
    const bookings = useSelector((state) => state.bookings);

    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    useMountEffect(async () => {
        await dispatch(getBookings());
        setLoading(false);
    });

    const dialogRef = useRef<DialogRef>(null);
    const onCreatePress = () => {
        dialogRef.current?.show();
    };

    const onRowPress = (e?: React.MouseEvent, booking?: Booking) => {
        history.push(`/bookings/${booking!.id}`);
    };

    return (
        <div className={styles.container}>
            <MaterialTable<Booking>
                title={"Bookings"}
                columns={columns}
                data={Object.values(bookings)}
                onRowClick={onRowPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create Booking",
                        isFreeAction: true,
                        onClick: onCreatePress,
                    },
                ]}
            />

            {loading && <Spinner overlay />}

            <Dialog<FormValues>
                dialogRef={dialogRef}
                createAction={async (values) => dispatch(createBooking(values))}
                title={`Create Booking`}
                validationSchema={validationSchema}
                initialValues={{
                    name: "",
                    email: "",
                    type: BookingType.TOUR_GUIDED,
                }}>
                <FormRow fullWidth>
                    <Input
                        name={"name"}
                        label={"Booking Name"} />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"email"} label={"Email"} placeholder={"john@example.com"} />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"type"} label={"Booking Type"}
                           options={[
                               {
                                   label: "Tour - Guided",
                                   value: BookingType.TOUR_GUIDED,
                               },
                               {
                                   label: "Tour - Self Guided",
                                   value: BookingType.TOUR_SELF_GUIDED,
                               },
                               {
                                   label: "Hire",
                                   value: BookingType.HIRE,
                               },
                           ]}
                    />
                </FormRow>
            </Dialog>
        </div>
    );
}
