/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import React, { PropsWithChildren, ReactElement, Suspense, useCallback } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as OpenroadLogo } from "../../../resources/svg/OpenroadLogo.svg";
import Routes from "../../../util/Routes";
// import Button from "../../widgets/button/Button";
import Spinner from "../../widgets/spinner/Spinner";
import styles from "./Dashboard.module.scss";
import Bike from "./routes/bike/Bike";
import BikeGroup from "./routes/bikeGroup/BikeGroup";
import BikeGroups from "./routes/bikeGroups/BikeGroups";
import Booking from "./routes/booking/Booking";
import Bookings from "./routes/bookings/Bookings";
import Reviews from "./routes/reviews/Reviews";
import Tour from "./routes/tour/Tour";
import Tours from "./routes/tours/Tours";

type Props = {} & RouteComponentProps;

type NavButtonProps = PropsWithChildren<{
    icon?: string;
    path?: string;
    onClick?: (e?: React.MouseEvent) => void;
}>;

function NavButton(props: NavButtonProps) {
    const { children, path, icon } = props;

    const history = useHistory();
    const match = useRouteMatch({
        path,
        exact: true,
    });

    const onClick = useCallback(
        (e: React.MouseEvent) => {
            if (path) {
                history.push(path);
            }

            props.onClick?.(e);
        },
        [path, props.onClick, history],
    );

    return (
        <MenuItem component={"div"} selected={!!match} onClick={onClick} className={styles.nav_button}>
            {icon && <Icon>{icon}</Icon>}
            {children}
        </MenuItem>
    );
}

export default function Dashboard(props: Props): ReactElement {
    return (
        <div className={styles.dashboard}>
            <div className={styles.side_nav}>
                <OpenroadLogo className={styles.logo} />
                <div className={styles.side_nav_sections}>
                    <NavButton path={Routes.Tours.root} icon={"card_travel"}>
                        Tours
                    </NavButton>
                    <NavButton path={Routes.BikeGroups.root} icon={"motorcycle"}>
                        Bikes
                    </NavButton>
                    <NavButton path={Routes.Bookings.root} icon={"confirmation_number"}>
                        Bookings
                    </NavButton>
                    <NavButton path={Routes.Reviews.root} icon={"rate_review"}>
                        Reviews
                    </NavButton>
                    <NavButton icon={"account_circle"}>Account</NavButton>
                    <NavButton icon={"exit_to_app"}>Sign out</NavButton>
                </div>
            </div>
            <div className={styles.content_window}>
                <div className={styles.content}>
                    <Suspense fallback={<Spinner center />}>
                        <Switch location={props.location}>
                            <Route path={Routes.Tours.Tour.root} component={Tour} />
                            <Route path={Routes.Tours.root} component={Tours} />

                            <Route path={Routes.BikeGroups.BikeGroup.Bike.root} component={Bike} />
                            <Route path={Routes.BikeGroups.BikeGroup.root} component={BikeGroup} />
                            <Route path={Routes.BikeGroups.root} component={BikeGroups} />

                            <Route path={Routes.Reviews.root} component={Reviews} />

                            <Route path={Routes.Bookings.Booking.root} component={Booking} />
                            <Route path={Routes.Bookings.root} component={Bookings} />

                            <Redirect path={"*"} to={Routes.Tours.root} />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </div>
    );
}
