/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Booking from "../../types/model/Booking";
import { Actions, Types } from "../actions/bookings";

const initialState: {
    [key: string]: Booking;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function bookings(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BOOKING: {
            const payload = action.payload as ActionValueTypes<Types.SET_BOOKING>;
            const booking = payload!;

            return {
                ...state,
                [booking.id]: booking,
            };
        }
        case Types.SET_BOOKINGS: {
            const payload = action.payload as ActionValueTypes<Types.SET_BOOKINGS>;
            const bookingsList = payload!;

            const newState = {
                ...state,
            };

            bookingsList.forEach((booking) => {
                newState[booking.id] = booking;
            });

            return newState;
        }
        default: {
            return state;
        }
    }
}
