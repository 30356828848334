/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum TourType {
    GUIDED = "GUIDED",
    SELF_GUIDED = "SELF_GUIDED",
}

export enum TourStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
}

export type TourProperties = {
    homepage: boolean;
    favourite: boolean;
}

export default interface Tour {
    id: string;
    slug: string;
    title: string;
    description: string;
    km: number;
    days: number;
    ridingDays: number;
    priceFrom: number;
    // images: string[];
    types: TourType[];
    status: TourStatus;
    properties: TourProperties
}
