/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import BikeBooking from "../../../types/model/BikeBooking";
import Booking from "../../../types/model/Booking";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getBookings(): Promise<Booking[]> {
    return get("/v1/bookings");
}

function getBooking(tourId: string): Promise<Booking> {
    return get(`/v1/bookings/${tourId}`);
}

function createBooking(data: Pick<Booking, "type" | "name" | "email">): Promise<Booking> {
    return post("/v1/bookings", data);
}

function updateBooking(
    tourId: string,
    data: Omit<Booking, "id" | "depositPaid" | "amountPaid" | "depositAmount" | "amount">,
): Promise<{
    booking: Booking;
    bikeBookings: BikeBooking[];
}> {
    return put(`/v1/bookings/${tourId}`, data);
}

const BookingsAPI = {
    getBookings,
    getBooking,
    createBooking,
    updateBooking,
};

export default BookingsAPI;
