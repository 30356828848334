/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 3/12/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { useDispatch as useReduxDispatch } from "react-redux";
import ReduxDispatch from "../types/ReduxDispatch";

export default function useDispatch(): ReduxDispatch {
    return useReduxDispatch<ReduxDispatch>();
}
