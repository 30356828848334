/* eslint-disable */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 22/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import {updateBikeBookings} from "../../../../../../../redux/actions/bikeBookings";
import BikeBookingDTO, {
    BikeBookingStatus,
    BikeBookingType, CUSTOM_BIKE_BOOKING_TOUR_TYPES,
    BIKE_BOOKING_TOUR_TYPES
} from "../../../../../../../types/model/BikeBooking";
import {BookingType} from "../../../../../../../types/model/Booking";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./BikeBookings.module.scss";
import BikeBooking from "./component/bikeBooking/BikeBooking";

type Props = {
    bookingId: string;
};

export type BikeBookingsFormValues = {
    bikeBookings: Omit<BikeBookingDTO, "id" | "bike" | "booking" | "tour" | "tourDate" | "startDate" | "endDate">[];
};

const validationSchema = Yup.object<BikeBookingsFormValues>({
    bikeBookings: Yup.array<BikeBookingsFormValues["bikeBookings"]>().of(Yup.object({
        name: Yup.string().required("Required"),
        bikeGroup: Yup.string().required("Required"),
        hasPassenger: Yup.boolean().when("type", {
            is: Yup.string().oneOf(BIKE_BOOKING_TOUR_TYPES),
            then: (schema: Yup.BooleanSchema) => schema.required("Required"),
        }),
        hasSingleRoom: Yup.boolean().when("type", {
            is: Yup.string().oneOf(BIKE_BOOKING_TOUR_TYPES),
            then: (schema: Yup.BooleanSchema) => schema.required("Required"),
        }),
        riderAmount: Yup.number().when("type", {
            is: Yup.string().oneOf(CUSTOM_BIKE_BOOKING_TOUR_TYPES),
            then: (schema: Yup.BooleanSchema) => schema.required("Required"),
        }),
        passengerAmount: Yup.number().when("type", {
            is: Yup.string().oneOf(CUSTOM_BIKE_BOOKING_TOUR_TYPES),
            then: (schema: Yup.BooleanSchema) => schema.required("Required"),
        }),
        singleRoomAmount: Yup.number().when("type", {
            is: Yup.string().oneOf(CUSTOM_BIKE_BOOKING_TOUR_TYPES),
            then: (schema: Yup.BooleanSchema) => schema.required("Required"),
        }),
        type: Yup.mixed()
            .oneOf(Object.values(BikeBookingType))
            .required("Required"),
        status: Yup.mixed()
            .oneOf(Object.values(BikeBookingStatus))
            .required("Required"),
    }))
});


function BikeBookingsArray(props: { bookingId: string } & FormikProps<BikeBookingsFormValues> & FieldArrayRenderProps) {
    const {values, push} = props;
    return (
        <>
            <div className={styles.bikeBookings}>
                {values.bikeBookings.map((bikeBooking, index) => (
                    <BikeBooking
                        {...props}
                        key={index}
                        bikeBooking={bikeBooking}
                        index={index}
                    />
                ))}
            </div>
            <Button onClick={() => push({
                name: "",
                bikeGroup: "",
                hasPassenger: false,
                hasSingleRoom: false,
                type: "",
                status: "",
            })} small>
                Add Bike Booking
            </Button>
        </>
    );
}


export default function BikeBookings(props: Props) {
    const {bookingId} = props;
    const bikeBookings = useSelector((state) => state.bikeBookings[bookingId]);
    const booking = useSelector((state) => state.bookings[bookingId]);

    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();

    const onSubmit = async (values: BikeBookingsFormValues) => {
        try {
            setError(null);
            await dispatch(updateBikeBookings(bookingId, values.bikeBookings));
        } catch (err) {
            setError(err.message);
        }
    };

    let disabledText: string | null = null;

    if (booking.type === BookingType.TOUR_GUIDED) {
        if (!booking.tour || !booking.tourDate) {
            disabledText = "Please select a tour and tour date above."
        }
    } else if (booking.type === BookingType.TOUR_SELF_GUIDED) {

        if (!booking.tour || !booking.startDate || !booking.endDate) {
            disabledText = "Please select a tour and start & end dates above."
        }

    } else if (booking.type === BookingType.HIRE) {
        if (!booking.startDate || !booking.endDate) {
            disabledText = "Please select a start and end dates above."
        }
    }


    return (
        <Card className={styles.bikeBookingsContainer}>
            <Formik<BikeBookingsFormValues>
                initialValues={{
                    bikeBookings: bikeBookings || [],
                }}
                onSubmit={onSubmit}
                enableReinitialize
                validationSchema={validationSchema}>
                {(formikProps: FormikProps<BikeBookingsFormValues>) => (
                    <Form>
                        <CardHeader
                            title={`Bike Bookings:`}
                            action={
                                <Button type={"submit"} disabled={!formikProps.isValid || !formikProps.dirty}>
                                    Save
                                </Button>
                            }
                        />

                        <ErrorBlock error={error}/>

                        <FieldArray name={"bikeBookings"}>
                            {(fieldArrayProps) => (
                                <BikeBookingsArray {...fieldArrayProps} {...formikProps} bookingId={bookingId}/>
                            )}
                        </FieldArray>

                        {disabledText && (
                            <div className={styles.disabled}>
                                <h4>{disabledText}</h4>
                            </div>
                        )}

                        {formikProps.isSubmitting && <Spinner fullscreen/>}
                    </Form>
                )}
            </Formik>
        </Card>
    );
}
