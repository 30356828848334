/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { FieldArrayRenderProps, FormikProps } from "formik";
import React, { useState } from "react";
import useMountEffect from "../../../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../../../hooks/useSelector";
import BikeBookingDTO, {
    BikeBookingStatus,
    BikeBookingType,
    BYOB_BIKE_BOOKING_TOUR_TYPES
} from "../../../../../../../../../types/model/BikeBooking";
import {
    BookingType,
    CUSTOM_TOUR_BOOKING_TYPES,
    TOUR_BOOKING_TYPES
} from "../../../../../../../../../types/model/Booking";
import Button from "../../../../../../../../widgets/button/Button";
import CheckBox from "../../../../../../../../widgets/checkBox/CheckBox";
import FormRow from "../../../../../../../../widgets/formRow/FormRow";
import Input, { SelectOption } from "../../../../../../../../widgets/input/Input";
import Spinner from "../../../../../../../../widgets/spinner/Spinner";
import { BikeBookingsFormValues } from "../../BikeBookings";
import styles from "./BikeBooking.module.scss";

type Props = {
    bikeBooking: Omit<BikeBookingDTO, "id" | "bike" | "booking" | "tour" | "tourDate" | "startDate" | "endDate">;
    bookingId: string;
    index: number;
} & FormikProps<BikeBookingsFormValues> & FieldArrayRenderProps;

export default function BikeBooking(props: Props) {
    const { bookingId, index, setFieldValue, values, remove } = props;
    const [loading, setLoading] = useState(true);

    const booking = useSelector((state) => state.bookings[bookingId]);
    const bikeGroups = useSelector((state) => state.bikeGroups);

    const bikeBooking = values.bikeBookings[index];

    const { type } = booking;
    const isHireBooking = type === BookingType.HIRE;
    const isTourBooking = TOUR_BOOKING_TYPES.includes(type);
    const isSelfGuidedTourBooking = type === BookingType.TOUR_SELF_GUIDED;
    const isCustomTourBooking = CUSTOM_TOUR_BOOKING_TYPES.includes(type);
    const isBYOB = BYOB_BIKE_BOOKING_TOUR_TYPES.includes(bikeBooking.type);

    const bikeGroupOptions = Object.values(bikeGroups).map((bg) => ({
        label: bg.name,
        value: bg.id,
    }));

    useMountEffect(async () => {
        setLoading(false);
    });

    let BOOKING_TYPES: SelectOption[] = [];
    if (isHireBooking) {
        BOOKING_TYPES = [{ label: "Hire", value: BikeBookingType.BIKE_HIRE }];
    } else if (isCustomTourBooking) {
        BOOKING_TYPES = [
            {
                label: "Custom Tour - Guided",
                value: BikeBookingType.CUSTOM_TOUR_GUIDED,
            },
            {
                label: "Custom Tour - Guided - BYOB",
                value: BikeBookingType.CUSTOM_TOUR_GUIDED_BYOB,
            },
            {
                label: "Custom Tour - Self Guided",
                value: BikeBookingType.CUSTOM_TOUR_SELF_GUIDED,
            },
            {
                label: "Custom Tour - Self Guided - BYOB",
                value: BikeBookingType.CUSTOM_TOUR_SELF_GUIDED_BYOB,
            }
        ];
    } else if (isSelfGuidedTourBooking) {
        BOOKING_TYPES = [
            {
                label: "Tour - Self Guided",
                value: BikeBookingType.TOUR_SELF_GUIDED,
            }, {
                label: "Tour - Self Guided - BYOB",
                value: BikeBookingType.TOUR_SELF_GUIDED_BYOB,
            }
        ];
    } else if (isTourBooking) {
        BOOKING_TYPES = [
            {
                label: "Tour - Guided",
                value: BikeBookingType.TOUR_GUIDED,
            }, {
                label: "Tour - Guided - BYOB",
                value: BikeBookingType.TOUR_GUIDED_BYOB,
            }
        ];
    }

    // const TOUR_BOOKING_TYPES = isGuidedTourBooking ? [

    // ] : [{
    //     label: "Self Guided Tour",
    //     value: BikeBookingType.TOUR_SELF_GUIDED,
    // },
    //     {
    //         label: "Self Guided Tour - BYOB",
    //         value: BikeBookingType.TOUR_SELF_GUIDED_BYOB,
    //     }
    // ];

    const onRemovePress = () => {
        remove(index);
    };

    return (
        <div className={styles.bike_booking}>
            <div>
                <span>Bike Booking: #{index + 1}</span>
            </div>

            <FormRow>
                <Input name={`bikeBookings[${index}].name`} label={"Name"} />
            </FormRow>
            <FormRow>
                <Input name={`bikeBookings[${index}].type`} label={"Type"}
                       options={BOOKING_TYPES} />
                <Input
                    name={`bikeBookings[${index}].status`}
                    label={"Status"}
                    options={[
                        {
                            label: "Active",
                            value: BikeBookingStatus.ACTIVE,
                        },
                        {
                            label: "Draft",
                            value: BikeBookingStatus.DRAFT,
                        },
                        {
                            label: "Cancelled",
                            value: BikeBookingStatus.CANCELLED,
                        },
                        {
                            label: "Deleted",
                            value: BikeBookingStatus.DELETED,
                        },
                    ]}
                />
            </FormRow>

            {!isBYOB && (
                <FormRow>
                    <Input name={`bikeBookings[${index}].bikeGroup`} label={"Bike"} options={bikeGroupOptions} />
                </FormRow>
            )}

            {!isHireBooking && (
                <FormRow>
                    <CheckBox name={`bikeBookings[${index}].hasPassenger`} label={"Passenger"}
                              disabled={bikeBooking.hasSingleRoom} />
                    <CheckBox name={`bikeBookings[${index}].hasSingleRoom`} label={"Single Room"}
                              disabled={bikeBooking.hasPassenger} />
                </FormRow>
            )}

            {(isCustomTourBooking || isSelfGuidedTourBooking) && (
                <FormRow>
                    <Input name={`bikeBookings[${index}].riderAmount`} label={"RiderAmount"} money />
                    <Input name={`bikeBookings[${index}].passengerAmount`} label={"Passenger Amount"} money disabled={!bikeBooking.hasPassenger} />
                    <Input name={`bikeBookings[${index}].singleRoomAmount`} label={"Single Room Amount"} money disabled={!bikeBooking.hasSingleRoom} />
                </FormRow>
            )}

            <Button red onClick={onRemovePress} small>
                Remove Bike Booking
            </Button>

            {loading && <Spinner overlay />}
        </div>
    );
}
