/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 8/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../types/model/User";
import Nullable from "../types/Nullable";
import useSelector from "./useSelector";

export default function useLoggedInState(): [boolean, Nullable<User>] {
    const user = useSelector((state) => state.user);
    return [!!user, user];
}
