/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import BikeBooking from "../../types/model/BikeBooking";
import BikeBookingsAPI from "./api/bikeBookings";
import { setBooking } from "./bookings";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_BIKE_BOOKINGS = "bikeBookings:SET_BIKE_BOOKINGS",
}

export const setBikeBookings = createAction<{
    bookingId: string;
    bikeBookings: BikeBooking[];
}>(Types.SET_BIKE_BOOKINGS);

export const Actions = {
    [Types.SET_BIKE_BOOKINGS]: setBikeBookings,
};
export type Actions = typeof Actions;

export const getBikeBookings = wrapper(BikeBookingsAPI.getBikeBookings, (dispatch, bikeBookings, getState, bookingId) => {
    dispatch(
        setBikeBookings({
            bookingId,
            bikeBookings,
        }),
    );
});

export const updateBikeBookings = wrapper(BikeBookingsAPI.updateBikeBookings, (dispatch, resp, getState, bookingId) => {

    const { booking, bikeBookings } = resp;

    dispatch(
        setBooking(booking),
    );

    dispatch(
        setBikeBookings({
            bookingId,
            bikeBookings,
        }),
    );
});
