/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import AttachMoneyRounded from "@material-ui/icons/AttachMoneyRounded";
import { isNaN, useField, useFormikContext } from "formik";
import React, { useCallback } from "react";

export type SelectOption = {
    label: string;
    value: string;
};

export type InputProps = {
    name: string;
    options?: SelectOption[];
    money?: boolean;
} & Omit<TextFieldProps, "name" | "value" | "error" | "variant">;

export default function Input(props: InputProps) {
    const { name, options, money, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const isSelect = !!options;

    const [field, meta] = fieldProps;
    let { value } = field;

    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = props.disabled ?? isSubmitting;

    const helperText = showError ? fieldError : props.helperText;

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (money) {
                e.target.value = `${Number(e.target.value) * 100}`;
            }
            field.onChange(e);
            props.onChange?.(e);
        },
        [field, props.onChange, money],
    );

    if (money) {
        value = Number(value) / 100;
        otherProps.InputProps = {
            ...(otherProps.InputProps || {}),
            type: "number",
            startAdornment: (
                <InputAdornment position={"start"}>
                    <AttachMoneyRounded />
                </InputAdornment>
            ),
        };
    }

    value = isNaN(value) ? "" : value ?? "";

    return (
        <TextField
            {...otherProps}
            {...field}
            value={value}
            select={isSelect}
            onChange={onChange}
            error={showError}
            disabled={disabled}
            SelectProps={{
                fullWidth: true,
            }}
            helperText={helperText}>
            {isSelect &&
                options!.map(({ label, value: optionValue }) => (
                    <MenuItem key={optionValue} value={optionValue}>
                        {label}
                    </MenuItem>
                ))}
        </TextField>
    );
}
