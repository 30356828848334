/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 28/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Form, Formik, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { updateTour } from "../../../../../../../redux/actions/tours";
import Tour, {TourProperties, TourStatus, TourType} from "../../../../../../../types/model/Tour";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Gallery from "../../../../../../widgets/gallery/Gallery";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import CheckBox from "../../../../../../widgets/checkBox/CheckBox";
import styles from "./TourDetailsCard.module.scss";

type Props = {
    tourId: string;
};

type FormValues = {
    type: string;
} & Omit<Tour, "id" | "types">;

const validationSchema = Yup.object<FormValues>({
    title: Yup.string().required("Required"),
    slug: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    km: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    days: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    ridingDays: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    priceFrom: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    type: Yup.string().required("Required"),
    status: Yup.mixed()
        .oneOf(Object.values(TourStatus))
        .required("Required"),
    properties: Yup.object().shape<TourProperties>({
        homepage: Yup.boolean(),
        favourite: Yup.boolean(),
    })
});

export default function TourDetailsCard(props: Props): ReactElement {
    const { tourId } = props;

    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();
    const tour = useSelector((state) => state.tours[tourId]);

    const onSubmit = async (values: FormValues) => {
        try {
            setError(null);
            await dispatch(updateTour(tour.id, {
                ...values,
                types: [
                    values.type as TourType
                ],
            }));
        } catch (err) {
            setError(err.message);
        }
    };

    const onSlugChange = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue("slug", (e.target.value || "").toLowerCase().replace(" ", "-"));
        };
    };

    return (
        <Formik initialValues={{
            ...tour,
            type: tour.types[0],
        }} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
            {({ isValid, dirty, values, setFieldValue, isSubmitting }: FormikProps<FormValues>) => (
                <>
                    <Card className={styles.details_card}>
                        <Form>
                            <CardHeader
                                title={tour?.title || "Loading..."}
                                action={(
                                    <Button type={"submit"} disabled={!isValid || !dirty}>
                                        Save
                                    </Button>
                                  )}
                            />
                            <ErrorBlock error={error} />
                            <div className={styles.info_card_block}>
                                <FormRow>
                                    <Input
                                        name={"status"}
                                        label={"Status"}
                                        options={[
                                            {
                                                label: "Active",
                                                value: TourStatus.ACTIVE,
                                            },
                                            {
                                                label: "Inactive",
                                                value: TourStatus.INACTIVE,
                                            },
                                            {
                                                label: "Deleted",
                                                value: TourStatus.DELETED,
                                            },
                                        ]}
                                    />
                                    <div />
                                </FormRow>

                                <FormRow>
                                    <Input
                                        name={"type"}
                                        label={"Type"}
                                        options={[
                                            {
                                                label: "Guided",
                                                value: TourType.GUIDED,
                                            },
                                            {
                                                label: "Self-Guided",
                                                value: TourType.SELF_GUIDED,
                                            },
                                        ]}
                                    />
                                    <div />
                                </FormRow>

                                <FormRow>
                                    <Input name={"title"} label={"Title"} placeholder={"The Grand Tour"} />
                                    <Input
                                        name={"slug"}
                                        label={"Slug"}
                                        placeholder={"grand-tour"}
                                        onChange={onSlugChange(setFieldValue)}
                                    />
                                </FormRow>
                                <FormRow>
                                    <Input name={"days"} label={"Days"} type={"number"} placeholder={"0"} />
                                    <Input name={"ridingDays"} label={"Riding Days"} type={"number"} placeholder={"0"} />
                                </FormRow>
                                <FormRow>
                                    <Input name={"km"} label={"Num KMs"} type={"number"} placeholder={"0"} />
                                    <Input name={"highlights"} label={"Highlights"} placeholder={"Highlights"} />
                                </FormRow>

                                <FormRow>
                                    <Input name={"priceFrom"} label={"Price From"} type={"number"} placeholder={"0"} money />
                                </FormRow>

                                <FormRow>
                                    <CheckBox name={"properties.homepage"} label={"Show on homepage"} />
                                </FormRow>
                            </div>

                            <ReactQuill value={values.description}
                                        modules={{
                                            clipboard: {
                                                matchVisual: false,
                                            }
                                        }}
                                        onChange={(value) => setFieldValue(`description`, value)} />

                             <Gallery name={"images"} />
                            {isSubmitting && <Spinner overlay />}
                        </Form>
                    </Card>
                </>
            )}
        </Formik>
    );
}
