import { AxiosRequestConfig } from "axios";
import instance from "./instance";

export default function del<T = any>(url: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
    return instance
        .delete(url, {
            data: body,
            ...config,
        })
        .then((data) => data?.data);
}
