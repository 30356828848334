/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/model/User";
import Nullable from "../../types/Nullable";
import { Action } from "../actions/helpers/createAction";
import { Actions, Types } from "../actions/user";

const initialState: Nullable<User> = null;

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function user(state = initialState, action: Action<Nullable<User>> = { type: null, payload: null }) {
    switch (action.type) {
        case Types.SET_USER: {
            return action.payload || null;
        }

        case Types.REMOVE_USER: {
            return null;
        }

        default: {
            return state;
        }
    }
}
