/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 19/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const BOX_SHADOW =
    "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.12) 0px 2px 5px 0px;";

const theme: ThemeOptions = {
    typography: {
        fontFamily: "Open Sans, sans-serif",
    },
    spacing: 9,
    overrides: {
        MuiTextField: {
            root: {
                marginBottom: 9,
                width: "100%",
            },
        },
        MuiInputBase: {
            inputAdornedStart: {
                paddingLeft: "0!important",
            },
            inputAdornedEnd: {
                paddingRight: "0!important",
            },
        },
        MuiInput: {
            root: {
                boxShadow: BOX_SHADOW,
                borderRadius: 4,
                padding: 0,
            },
            input: {
                lineHeight: 20,
                minHeight: 28,
                fontSize: 14,
                padding: "4px 9px",
            },
            formControl: {
                "label + &": {
                    marginTop: 18,
                },
            },
        },
        MuiInputAdornment: {
            root: {},
            positionStart: {
                marginRight: 0,
                marginLeft: 4,
            },
            positionEnd: {
                marginRight: 9,
                marginLeft: 0,
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: 14,
            },
            formControl: {
                transform: "translate(9px, 28px) scale(1)",
            },
            shrink: {
                transform: "translate(0, -1px) scale(0.8)",
            },
        },
        MuiSelect: {
            select: {
                height: 28,
            },
            selectMenu: {
                lineHeight: 2,
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiCard: {
            root: {
                padding: 18,
                marginBottom: 18,
                boxShadow: BOX_SHADOW,
            },
        },
        MuiCardHeader: {
            root: {
                padding: 0,
                marginBottom: 18,
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: BOX_SHADOW,
                minWidth: 369,
            },
        },
        MuiButton: {
            root: {
                padding: "4px 14px",
                minWidth: "auto",
            },
            label: {
                justifyContent: "unset",
                padding: 0,
                fontSize: 14,
                textTransform: "none",
                height: 28,
            },
            sizeSmall: {
                padding: "4px 14px",
            },
            textSizeSmall: {
                fontSize: 12,
            }

        },
        MuiExpansionPanel: {
            root: {
                "&:before": {
                    display: "none",
                },
                border: "none",
                boxShadow: BOX_SHADOW,
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
};

export default theme;
