/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Form, Formik, FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { updateBooking } from "../../../../../../../redux/actions/bookings";
import { getTourDates } from "../../../../../../../redux/actions/tourDates";
import Booking, {
    BookingStatus,
    BookingType,
    CUSTOM_TOUR_BOOKING_TYPES,
    GUIDED_TOUR_BOOKING_TYPES,
    SELF_GUIDED_TOUR_BOOKING_TYPES,
    TOUR_BOOKING_TYPES
} from "../../../../../../../types/model/Booking";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import CheckBox from "../../../../../../widgets/checkBox/CheckBox";
import DateInput from "../../../../../../widgets/dateInput/DateInput";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./BookingDetailsCard.module.scss";

type Props = {
    bookingId: string;
};

type FormValues = Omit<Booking, "id">;

const validationSchema = Yup.object<FormValues>({
    name: Yup.string().required("Required"),
    email: Yup.string()
        .email("Invalid Email")
        .required("Required"),
    description: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
    amount: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    amountPaid: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    depositAmount: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    depositPaid: Yup.boolean(),
    discountAmount: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    discountPercentage: Yup.number()
        .min(0)
        .max(1, "Discount Percentage must be between 0 and 1. So 0.5 for 50% discount."),
    surchargeAmount: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    surchargePercentage: Yup.number()
        .min(0)
        .max(1, "Surcharge Percentage must be between 0 and 1. So 0.5 for 50% surcharge."),
    startDate: Yup.string().when("type", {
        is: Yup.string().oneOf([BookingType.HIRE, BookingType.TOUR_SELF_GUIDED]),
        then: (schema: Yup.StringSchema) => schema.required("Required"),
        otherwise: Yup.string().nullable(true),
    }),
    endDate: Yup.string().when("type", {
        is: Yup.string().oneOf([BookingType.HIRE, BookingType.TOUR_SELF_GUIDED]),
        then: (schema: Yup.StringSchema) => schema.required("Required"),
        otherwise: Yup.string().nullable(true),
    }),
    tour: Yup.string().when("type", {
        is: Yup.string().oneOf([BookingType.TOUR_GUIDED, BookingType.TOUR_SELF_GUIDED]),
        then: (schema: Yup.StringSchema) => schema.required("Required"),
        otherwise: Yup.string().nullable(true),
    }),
    tourDate: Yup.string().when("type", {
        is: BookingType.TOUR_GUIDED,
        then: (schema: Yup.StringSchema) => schema.required("Required"),
        otherwise: Yup.string().nullable(true),
    }),
    type: Yup.mixed()
        .oneOf(Object.values(BookingType))
        .required("Required"),
    status: Yup.mixed()
        .oneOf(Object.values(BookingStatus))
        .required("Required"),
});


function BookingDetailsCardForm(props: Props & FormikProps<FormValues> & { setError: (msg: string | null) => void; error: string | null }) {
    const { bookingId, values, isSubmitting, isValid, dirty, error, setFieldValue } = props;

    const { tour: tourId, type } = values;

    const isHireBooking = type === BookingType.HIRE;
    const isTourBooking = TOUR_BOOKING_TYPES.includes(type);
    const isCustomTourBooking = CUSTOM_TOUR_BOOKING_TYPES.includes(type);
    const isGuidedTourBooking = GUIDED_TOUR_BOOKING_TYPES.includes(type);
    const isSelfGuidedTourBooking = SELF_GUIDED_TOUR_BOOKING_TYPES.includes(type);

    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const booking: Booking | null = useSelector((state) => state.bookings[bookingId]) || null;

    const tours = useSelector((state) => state.tours);
    const tourOptions = Object.values(tours).map((tourOption) => ({
        label: tourOption.title,
        value: tourOption.id,
    }));

    const tour = tours[booking?.tour] || null;
    const tourDates = useSelector((state) => state.tourDates[tourId]);
    const tourDateOptions = Object.values(tourDates || {}).map((tourDateOption) => ({
        label: `${DateTime.fromISO(tourDateOption.startDate).toFormat("dd MMM yyyy")} - ${DateTime.fromISO(
            tourDateOption.endDate,
        ).toFormat("dd MMM yyyy")}`,
        value: tourDateOption.id,
    }));

    useEffect(() => {
        const asyncCall = async () => {
            setLoading(true);
            if (tourId) {
                await dispatch(getTourDates(tourId));
            }
            setLoading(false);
        };
        try {
            asyncCall();
        } catch (e) {
            setLoading(false);
        }
    }, [tourId]);

    const onBookingTypeChange = () => {
        setFieldValue("tour", "");
        setFieldValue("tourDate", "");
        setFieldValue("startDate", "");
        setFieldValue("endDate", "");
    };

    const onTourChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTourId = e.target.value;
        setLoading(true);
        setFieldValue("tourDate", "");
        await dispatch(getTourDates(newTourId));
        setLoading(false);
    };

    const endDateMinDate = DateTime.fromISO(values.startDate).plus({ days: tour?.days || 1 });

    return (
        <Form>
            <CardHeader
                title={`Booking: ${booking?.name || "Loading..."}`}
                action={(
                    <Button type={"submit"} disabled={!isValid || !dirty}>
                        Save
                    </Button>
                )}
            />

            <ErrorBlock error={error} />

            <div className={styles.info_card_block}>
                <FormRow>
                    <Input
                        name={"status"}
                        label={"Status"}
                        options={[
                            {
                                label: "Draft",
                                value: BookingStatus.DRAFT,
                            },
                            {
                                label: "Active",
                                value: BookingStatus.ACTIVE,
                            },
                            {
                                label: "Cancelled",
                                value: BookingStatus.CANCELLED,
                            },
                            {
                                label: "Deleted",
                                value: BookingStatus.DELETED,
                            },
                        ]}
                    />
                    <Input
                        name={"type"}
                        label={"Booking Type"}
                        onChange={onBookingTypeChange}
                        options={[
                            {
                                label: "Tour - Guided",
                                value: BookingType.TOUR_GUIDED,
                            },
                            {
                                label: "Tour - Self Guided",
                                value: BookingType.TOUR_SELF_GUIDED,
                            },
                            {
                                label: "Custom Tour - Guided",
                                value: BookingType.CUSTOM_TOUR_GUIDED,
                            },
                            {
                                label: "Custom Tour - Self Guided",
                                value: BookingType.CUSTOM_TOUR_SELF_GUIDED,
                            },
                            {
                                label: "Hire",
                                value: BookingType.HIRE,
                            },
                        ]}
                    />
                </FormRow>
                {isTourBooking && (
                    <FormRow>
                        <Input
                            name={"tour"}
                            label={"Tour"}
                            options={tourOptions}
                            onChange={onTourChange}
                        />

                        {isGuidedTourBooking && (
                            <Input
                                name={"tourDate"}
                                label={"Tour Date"}
                                options={tourDateOptions}
                            />
                        )}
                    </FormRow>
                )}
                
                {(isHireBooking || isSelfGuidedTourBooking || isCustomTourBooking) && (
                    <FormRow>
                        <DateInput
                            name={"startDate"}
                            label={"Start Date"}
                        />
                        <DateInput
                            minDate={endDateMinDate}
                            name={"endDate"}
                            label={"End Date"}
                        />
                    </FormRow>
                )}
                
                
                <FormRow>
                    <Input name={"name"} label={"Name"} placeholder={"John Doe"} />
                </FormRow>
                <FormRow>
                    <Input
                        name={"description"}
                        label={"Description"}
                        placeholder={"John Doe - Tour Client (Shows in stripe)"}
                    />
                </FormRow>
                <FormRow>
                    <Input name={"email"} label={"Email"} placeholder={"john@example.com"} />
                    <Input name={"phone"} label={"Phone"} placeholder={"+64 021 3456 789"} type={"phone"} />
                </FormRow>
                <FormRow>
                    <Input name={"address"} label={"Address"} placeholder={"Address"} />
                </FormRow>
                <FormRow>
                    <Input name={"discountAmount"} label={"Discount Amount"} money disabled={!!values.discountPercentage} />
                    <Input name={"discountPercentage"} label={"Discount Percentage"} type={"number"} disabled={!!values.discountAmount} />
                </FormRow>
                <FormRow>
                    <Input name={"surchargeAmount"} label={"Surcharge Amount"} money disabled={!!values.surchargePercentage} />
                    <Input name={"surchargePercentage"} label={"Surcharge Percentage"} type={"number"} disabled={!!values.surchargeAmount} />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"amount"} label={"Total Amount"} money disabled />
                    <Input name={"depositAmount"} label={"Deposit Amount"} money disabled />
                    <Input name={"amountPaid"} label={"Amount Paid"} money />
                    <CheckBox name={"depositPaid"} label={"Deposit Paid"} />
                </FormRow>
            </div>
            {(loading || isSubmitting) && <Spinner fullscreen />}
        </Form>
    );
}

export default function BookingDetailsCard(props: Props) {
    const { bookingId } = props;
    const booking = useSelector((state) => state.bookings[bookingId]);
    
    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();

    const onSubmit = async (values: FormValues) => {
        try {
            setError(null);
            await dispatch(updateBooking(bookingId, values));
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Card className={styles.booking_details_card}>
            <Formik initialValues={booking || {}}
                    onSubmit={onSubmit}
                    enableReinitialize
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<FormValues>) => (
                    <BookingDetailsCardForm {...formikProps} {...props} error={error} setError={setError} />
                )}
            </Formik>
        </Card>
    );
}
