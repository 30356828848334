/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classnames from "classnames";
import React from "react";
import Nullable from "../../../types/Nullable";
import styles from "./ErrorBlock.module.scss";

type Props = {
    className?: string;
    error?: Nullable<any>;
};
export default function ErrorBlock(props: Props) {
    const className = classnames(styles.error, props.className);
    const { error } = props;

    if (!error) {
        return null;
    }

    return (
        <div className={className}>
            <span>{error}</span>
        </div>
    );
}
