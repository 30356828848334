/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import classnames from "classnames";
import { useField, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, { ReactElement } from "react";
import styles from "./DateInput.module.scss";

type Props = {
    name: string;
    className?: string;
    pickerClassName?: string;
} & Omit<DatePickerProps, "value" | "onChange">;

export default function DateInput(props: Props): ReactElement {

    const { name, ...otherProps } = props;

    const { isSubmitting, setFieldValue } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;
    let { value } = field;

    if (value) {
        value = DateTime.fromISO(value);
    }

    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = props.disabled ?? isSubmitting;

    const helperText = showError ? fieldError : props.helperText;

    const className = classnames(styles.date_time_picker_wrapper, props.className);
    const pickerClassName = classnames(
        styles.date_time_picker,
        {
            [styles.disabled]: disabled,
        },
        props.pickerClassName,
    );

    const onChange = (date: DateTime) => {
        const formattedDate = date.toFormat("yyyy-MM-dd");
        setFieldValue(name, formattedDate);
    };

    return (
        <div className={className}>
            <DatePicker
                {...otherProps}
                {...field}
                disablePast
                value={value}
                helperText={helperText}
                onChange={onChange}
                className={pickerClassName}
                disabled={disabled}
                variant={"inline"}
                format={"dd MMMM yyyy"}
                autoOk
                disableToolbar
                InputProps={{
                    disableUnderline: true,
                }}
                PopoverProps={{
                    className: styles.date_time_picket_popover,
                }}
            />
        </div>
    );
}
