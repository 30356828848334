/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Review from "../../types/model/Review";
import { Actions, Types } from "../actions/reviews";

const initialState: {
    [key: string]: Review;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function reviews(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_REVIEW: {
            const payload = action.payload as ActionValueTypes<Types.SET_REVIEW>;
            const review = payload!;

            return {
                ...state,
                [review.id]: review,
            };
        }
        case Types.SET_REVIEWS: {
            const payload = action.payload as ActionValueTypes<Types.SET_REVIEWS>;
            const reviewsList = payload!;

            const newState = {
                ...state,
            };

            reviewsList.forEach((review) => {
                newState[review.id] = review;
            });

            return newState;
        }
        case Types.REMOVE_REVIEW: {
            const payload = action.payload as ActionValueTypes<Types.REMOVE_REVIEW>;
            const reviewId = payload!;

            const newState = {
                ...state,
            };

            delete newState[reviewId];

            return newState;
        }
        default: {
            return state;
        }
    }
}
