/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import MaterialTable, { Column } from "material-table";
import React, { ReactElement, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { createBikeGroup, getBikeGroups } from "../../../../../redux/actions/bikes";
import BikeGroup from "../../../../../types/model/BikeGroup";
import Button from "../../../../widgets/button/Button";
import Input from "../../../../widgets/input/Input";
import Spinner from "../../../../widgets/spinner/Spinner";
import styles from "../../Dashboard.module.scss";

type Props = {} & RouteComponentProps;

const columns: Column<BikeGroup>[] = [
    {
        title: "Bike",
        field: "name",
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Pick<BikeGroup, "name">;

const validationSchema = Yup.object<FormValues>({
    name: Yup.string().required("Required"),
});

export default function BikeGroups(props: Props): ReactElement {
    const { history } = props;
    const [createBikeGroupDialogOpen, setTourDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const bikeGroups = useSelector((state) => state.bikeGroups);

    const [loading, setLoading] = useState(true);
    useMountEffect(async () => {
        await dispatch(getBikeGroups());
        setLoading(false);
    });

    const onCreateNewBikeGroupPress = async () => {
        setTourDialogOpen(true);
    };

    const onCreateNewBikeGroupSubmitPress = async (values: FormValues) => {
        const bikeGroup = await dispatch(createBikeGroup(values));
        history.push(`/bike-groups/${bikeGroup.id}`);
    };

    const closeCreateBikeGroupDialog = () => {
        setTourDialogOpen(false);
    };

    const onTourPress = (e?: React.MouseEvent, bikeGroup?: BikeGroup) => {
        history.push(`/bike-groups/${bikeGroup?.id}`);
    };

    return (
        <div className={styles.container}>
            <MaterialTable<BikeGroup>
                title={"Bike Groups"}
                columns={columns}
                data={Object.values(bikeGroups)}
                onRowClick={onTourPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create Bike Group",
                        isFreeAction: true,
                        onClick: onCreateNewBikeGroupPress,
                    },
                ]}
            />

            <Dialog open={createBikeGroupDialogOpen}>
                <Formik<FormValues>
                    onSubmit={onCreateNewBikeGroupSubmitPress}
                    validationSchema={validationSchema}
                    initialValues={{
                        name: "",
                    }}>
                    {({ isValid, dirty, isSubmitting }) => (
                        <Form>
                            <DialogTitle>Create Bike Group</DialogTitle>
                            <DialogContent>
                                <Input name={"name"} label={"Bike Group Name"} placeholder={"Triumph Tiger 800xr"} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeCreateBikeGroupDialog} plain>
                                    Cancel
                                </Button>
                                <Button type={"submit"} disabled={!isValid || !dirty} loading={isSubmitting}>
                                    Create
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>

            {loading && <Spinner overlay />}
        </div>
    );
}
