/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Bike from "../../../types/model/Bike";
import BikeGroup from "../../../types/model/BikeGroup";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

// Bike Groups

function getBikeGroups(): Promise<BikeGroup[]> {
    return get("/v1/bike-groups");
}

function getBikeGroup(bikeGroupId: string): Promise<BikeGroup> {
    return get(`/v1/bike-groups/${bikeGroupId}`);
}

function createBikeGroup(data: Pick<BikeGroup, "name">): Promise<BikeGroup> {
    return post("/v1/bike-groups", data);
}

function updateBikeGroup(bikeGroupId: string, data: Omit<BikeGroup, "id">): Promise<BikeGroup> {
    return put(`/v1/bike-groups/${bikeGroupId}`, data);
}

function deleteBikeGroup(bikeGroupId: string): Promise<boolean> {
    return del(`/v1/bike-groups/${bikeGroupId}`);
}

// Bikes

function getBikes(bikeGroupId: string): Promise<Bike[]> {
    return get(`/v1/bike-groups/${bikeGroupId}/bikes`);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getBike(bikeId: string, bikeGroupId: string): Promise<Bike> {
    return get(`/v1/bikes/${bikeId}`);
}

function createBike(bikeGroupId: string, data: Omit<Bike, "id" | "status" | "bikeGroup">): Promise<Bike> {
    return post(`/v1/bike-groups/${bikeGroupId}/bikes`, data);
}

function updateBike(bikeId: string, data: Omit<Bike, "id" | "bikeGroup">): Promise<Bike> {
    return put(`/v1/bikes/${bikeId}`, data);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deleteBike(bikeId: string, bikeGroupId: string): Promise<boolean> {
    return del(`/v1/bikes/${bikeId}`);
}

const BikesAPI = {
    // Bike Groups
    getBikeGroups,
    getBikeGroup,
    createBikeGroup,
    updateBikeGroup,
    deleteBikeGroup,

    // Bikes
    getBikes,
    getBike,
    createBike,
    updateBike,
    deleteBike,
};

export default BikesAPI;
