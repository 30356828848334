/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum BikeGroupStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
}

export default interface BikeGroup {
    id: string;
    name: string;
    description: string;
    pricePerDay3To6Low: number;
    pricePerDay7To20Low: number;
    pricePerDay21PlusLow: number;
    pricePerDay3To6High: number;
    pricePerDay7To20High: number;
    pricePerDay21PlusHigh: number;
    cc: number;
    power: number;
    engineConfiguration: string;
    torqueNm: number;
    torqueRevs: number;
    fuelCapacity: number;
    weight: number;
    windProtection: string;
    seatHeight: number;
    luggageSystem: string;
    finalDrive: string;
    otherFeatures: string;
    images: string[];
    status: BikeGroupStatus;
}
