/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { PropsWithChildren, useCallback } from "react";
import useDispatch from "../../../hooks/useDispatch";
import useSelector from "../../../hooks/useSelector";
import { removeSnackbar } from "../../../redux/actions/snackbars";
import Snackbar, { SnackbarConfig } from "./Snackbar";
import styles from "./SnackbarContainer.module.scss";

export default function SnackbarContainer({ children }: PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const snackbars = useSelector((state) => state.snackbars);

    const onClose = useCallback(
        ({ id }: SnackbarConfig) => {
            dispatch(removeSnackbar(id));
        },
        [dispatch],
    );

    return (
        <>
            {children}
            <div className={styles.snackbar_container}>
                {snackbars.map((config: SnackbarConfig) => (
                    <Snackbar key={config.id} snackbarShown onClose={onClose} config={config} />
                ))}
            </div>
        </>
    );
}
