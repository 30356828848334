/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Tour from "../../types/model/Tour";
import TourItineraryItem from "../../types/model/TourItineraryItem";
import ToursAPI from "./api/tours";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_TOUR = "tours:SET_TOUR",
    SET_TOURS = "tours:SET_TOURS",
    REMOVE_TOUR = "tours:REMOVE_TOUR",
    SET_TOUR_ITINERARY = "tourItinerary:SET_TOUR_ITINERARY",
}

export const setTour = createAction<Tour>(Types.SET_TOUR);
export const setTours = createAction<Tour[]>(Types.SET_TOURS);
export const removeTour = createAction<string>(Types.REMOVE_TOUR);

export const setTourItinerary = createAction<{ tourId: string; items: TourItineraryItem[] }>(Types.SET_TOUR_ITINERARY);

export const Actions = {
    [Types.SET_TOUR]: setTour,
    [Types.SET_TOURS]: setTours,
    [Types.REMOVE_TOUR]: removeTour,
    [Types.SET_TOUR_ITINERARY]: setTourItinerary,
};
export type Actions = typeof Actions;

export const getTours = wrapper(ToursAPI.getTours, (dispatch, tours) => {
    dispatch(setTours(tours));
});

export const getTour = wrapper(ToursAPI.getTour, (dispatch, tour) => {
    dispatch(setTour(tour));
});

export const createTour = wrapper(ToursAPI.createTour, (dispatch, tour) => {
    dispatch(setTour(tour));
});

export const updateTour = wrapper(ToursAPI.updateTour, (dispatch, tour) => {
    dispatch(setTour(tour));
});

export const deleteTour = wrapper(ToursAPI.deleteTour, (dispatch, bool, getState, tourId) => {
    dispatch(removeTour(tourId));
});

export const getTourItinerary = wrapper(ToursAPI.getTourItinerary, (dispatch, items, getState, tourId) => {
    dispatch(
        setTourItinerary({
            tourId,
            items,
        }),
    );
});

export const updateTourItinerary = wrapper(ToursAPI.updateTourItinerary, (dispatch, items, getState, tourId) => {
    dispatch(
        setTourItinerary({
            tourId,
            items,
        }),
    );
});
