/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import MaterialTable, { Column } from "material-table";
import React, { ReactElement, useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import { createTour } from "../../../../../redux/actions/tours";
import Tour from "../../../../../types/model/Tour";
import Button from "../../../../widgets/button/Button";
import Input from "../../../../widgets/input/Input";
import styles from "./Tours.module.scss";

type Props = {} & RouteComponentProps;

const columns: Column<Tour>[] = [
    {
        title: "Tour",
        field: "title",
    },
    {
        title: "Slug",
        field: "slug",
    },
    {
        title: "Type",
        field: "types",
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Pick<Tour, "title">;

const validationSchema = Yup.object<FormValues>({
    title: Yup.string().required("Required"),
});

export default function Tours(props: Props): ReactElement {
    const { history } = props;
    const dispatch = useDispatch();
    const tours = useSelector((state) => state.tours);

    // const [loading, setLoading] = useState(false);
    // useMountEffect(async () => {
    //     await dispatch(getTours());
    //     setLoading(false);
    // });

    const [createDialogShown, setCreateDialogShown] = useState(false);
    const onCreatePress = useCallback(() => {
        setCreateDialogShown(true);
    }, [setCreateDialogShown]);

    const onCreateConfirmPress = useCallback(
        async (values: FormValues) => {
            const tour = await dispatch(createTour(values));
            history.push(`/tours/${tour.id}`);
        },
        [dispatch, history],
    );

    const closeCreateDialog = useCallback(() => {
        setCreateDialogShown(false);
    }, [setCreateDialogShown]);

    const onRowPress = useCallback(
        (e?: React.MouseEvent, tour?: Tour) => {
            history.push(`/tours/${tour!.id}`);
        },
        [history],
    );

    return (
        <div className={styles.tours}>
            <MaterialTable<Tour>
                title={"Tours"}
                columns={columns}
                data={Object.values(tours)}
                onRowClick={onRowPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create BikeGroup",
                        isFreeAction: true,
                        onClick: onCreatePress,
                    },
                ]}
            />

            <Formik<FormValues>
                onSubmit={onCreateConfirmPress}
                validationSchema={validationSchema}
                initialValues={{
                    title: "",
                }}>
                {({ isValid, dirty, isSubmitting }) => (
                    <Dialog open={createDialogShown} disableBackdropClick={isSubmitting} disableEscapeKeyDown={isSubmitting}>
                        <Form>
                            <DialogTitle>Create Tour</DialogTitle>
                            <DialogContent>
                                <Input name={"title"} label={"Title"} placeholder={"The Grand Tour"} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeCreateDialog} plain disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <Button type={"submit"} disabled={!isValid || !dirty} loading={isSubmitting}>
                                    Create
                                </Button>
                            </DialogActions>
                        </Form>
                    </Dialog>
                )}
            </Formik>

            {/* {loading && <Spinner overlay />} */}
        </div>
    );
}
