/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Booking from "../../types/model/Booking";
import BookingsAPI from "./api/bookings";
import {setBikeBookings} from "./bikeBookings";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_BOOKING = "bookings:SET_BOOKING",
    SET_BOOKINGS = "bookings:SET_BOOKINGS",
}

export const setBooking = createAction<Booking>(Types.SET_BOOKING);
export const setBookings = createAction<Booking[]>(Types.SET_BOOKINGS);

export const Actions = {
    [Types.SET_BOOKING]: setBooking,
    [Types.SET_BOOKINGS]: setBookings,
};
export type Actions = typeof Actions;

export const getBookings = wrapper(BookingsAPI.getBookings, (dispatch, bookings) => {
    dispatch(setBookings(bookings));
});

export const getBooking = wrapper(BookingsAPI.getBooking, (dispatch, booking) => {
    dispatch(setBooking(booking));
});

export const createBooking = wrapper(BookingsAPI.createBooking, (dispatch, booking) => {
    dispatch(setBooking(booking));
});

export const updateBooking = wrapper(BookingsAPI.updateBooking, (dispatch, resp, getState, bookingId) => {
    const { booking, bikeBookings } = resp;

    dispatch(
        setBooking(booking),
    );

    dispatch(
        setBikeBookings({
            bookingId,
            bikeBookings,
        }),
    );
});
