/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 15/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import {FieldArray, FieldArrayRenderProps, FieldHelperProps, FieldInputProps, FieldMetaProps, useField} from "formik";
import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import ImageGallery, {PhotoProps, RenderImageProps} from "react-photo-gallery";
import {SortableContainer, SortableContainerProps, SortableElement, SortEnd} from "react-sortable-hoc";
import ImagesAPI from "../../../redux/actions/api/images";
import Spinner from "../spinner/Spinner";
import styles from "./Gallery.module.scss";

export type GalleryProps = {
    name: string;
    className?: string;
};

type SortableGalleryProps = {
    className?: string;
    images: PhotoProps[];
    columns: number;
    onRemoveImagePress: (url: string) => void;
} & SortableContainerProps;

type SortableImageProps = {
    onRemoveImagePress: (url: string) => void;
} & RenderImageProps;

function Image(props: SortableImageProps) {
    const { photo, onRemoveImagePress } = props;
    const { src, height, width } = photo;
    return (
        <div key={src} style={{ backgroundImage: `url(${src})`, width, height }} className={styles.image}>
            <div className={styles.image_remove_button_wrapper}>
                <IconButton className={styles.image_remove_button} onClick={() => onRemoveImagePress(src)}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </div>
    );
}

const SortableImage = SortableElement(Image);

const SortableGallery = SortableContainer<SortableGalleryProps>((props: SortableGalleryProps) => {
    const { images, onRemoveImagePress, columns } = props;
    return (
        <ImageGallery
            photos={images}
            columns={columns}
            renderImage={(childProps: RenderImageProps) => <SortableImage {...childProps} onRemoveImagePress={onRemoveImagePress} />}
        />
    );
});

function WrappedGallery(
    props: { fieldProps: [FieldInputProps<string[]>, FieldMetaProps<string[]>, FieldHelperProps<string[]>] } & FieldArrayRenderProps &
        GalleryProps,
) {
    const { fieldProps, push, remove, move } = props;
    const [field] = fieldProps;
    const { value } = field;
    const images = value || [];

    const [loading, setLoading] = useState(false);

    const convertedImages: PhotoProps[] = images.map((image: string) => ({
        src: image,
        width: 2,
        height: 1,
    }));

    const onChange = (sort: SortEnd) => {
        const { oldIndex, newIndex } = sort;
        move(oldIndex, newIndex);
    };

    const onRemoveImagePress = (url: string) => {
        const index = images.indexOf(url);
        console.log("url", url)
        console.log("index", index)
        if (index || index === 0) {
            remove(index);
        }
    };

    const onDropAccepted = async (newImages: File[] /* , event: DropEvent */) => {
        setLoading(true);
        const uploads = newImages.map((image) => {
            return ImagesAPI.uploadImage(image);
        });


        let i = 0;
        await uploads.map(async (promise) => {
            i += 1;
            try {
                const url =  await promise;
                push(url);
            } catch (e) {

            }

            if (i === uploads.length) {
                setLoading(false);
            }
        });

    };

    const className = classNames(styles.gallery, props.className);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted,
        accept: "image/*",
    });
    const dropzoneClassname = classNames(styles.dropzone, {
        [styles.active]: isDragActive,
    });

    return (
        <div className={className}>
            <CardHeader title={"Images"} />
            <SortableGallery
                className={className}
                images={convertedImages}
                axis={"xy"}
                onSortEnd={onChange}
                columns={4}
                distance={1}
                onRemoveImagePress={onRemoveImagePress}
            />

            <div {...getRootProps()} className={dropzoneClassname}>
                <input {...getInputProps()} />
                <Typography>Drag Files or Click to Browse</Typography>
            </div>
            {loading && <Spinner fullscreen />}
        </div>
    );
}

export default function Gallery(props: GalleryProps) {
    const { name } = props;

    const fieldProps = useField<string[]>(name);

    return (
        <FieldArray
            name={name}
            render={(renderProps: FieldArrayRenderProps) => <WrappedGallery {...renderProps} {...props} fieldProps={fieldProps} />}
        />
    );
}
