/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import TourItineraryItem from "../../types/model/TourItineraryItem";
import { Actions, Types } from "../actions/tours";

const initialState: {
    [key: string]: TourItineraryItem[];
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function tourItinerary(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_TOUR_ITINERARY: {
            const payload = action.payload as ActionValueTypes<Types.SET_TOUR_ITINERARY>;
            const { tourId, items } = payload!;

            return {
                ...state,
                [tourId]: items,
            };
        }
        default: {
            return state;
        }
    }
}
