import post from "./util/post";

export default class ImagesAPI {

    static buildImageData(image: File) {
        const formData = new FormData();
        formData.append("image", image);
        return formData;
    }


    static uploadImage(image: File) {
        const formData = this.buildImageData(image);
        return post<string>("/v1/images/upload", formData);
    }

}
