/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import BikeGroup from "../../types/model/BikeGroup";
import { Actions, Types } from "../actions/bikes";

const initialState: {
    [key: string]: BikeGroup;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function bikeGroups(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BIKE_GROUP: {
            const payload = action.payload as ActionValueTypes<Types.SET_BIKE_GROUP>;
            const bikeGroup = payload!;

            return {
                ...state,
                [bikeGroup.id]: bikeGroup,
            };
        }
        case Types.SET_BIKE_GROUPS: {
            const payload = action.payload as ActionValueTypes<Types.SET_BIKE_GROUPS>;
            const bikeGroupsList = payload!;

            const newState = {
                ...state,
            };

            bikeGroupsList.forEach((bikeGroup) => {
                newState[bikeGroup.id] = bikeGroup;
            });

            return newState;
        }
        case Types.REMOVE_BIKE_GROUP: {
            const payload = action.payload as ActionValueTypes<Types.REMOVE_BIKE_GROUP>;
            const bikeGroupId = payload!;

            const newState = {
                ...state,
            };

            delete newState[bikeGroupId];

            return newState;
        }
        default: {
            return state;
        }
    }
}
