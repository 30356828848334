/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Bike from "../../types/model/Bike";
import BikeGroup from "../../types/model/BikeGroup";
import BikesAPI from "./api/bikes";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_BIKE_GROUP = "bikeGroup:SET_BIKE_GROUP",
    SET_BIKE_GROUPS = "bikeGroup:SET_BIKE_GROUPS",
    REMOVE_BIKE_GROUP = "bikeGroup:REMOVE_BIKE_GROUP",
    SET_BIKE = "bike:SET_BIKE",
    SET_BIKES = "bike:SET_BIKES",
    REMOVE_BIKE = "bike:REMOVE_BIKE",
}

export const setBikeGroup = createAction<BikeGroup>(Types.SET_BIKE_GROUP);
export const setBikeGroups = createAction<BikeGroup[]>(Types.SET_BIKE_GROUPS);
export const removeBikeGroup = createAction<string>(Types.REMOVE_BIKE_GROUP);

export const setBike = createAction<{
    bikeGroupId: string;
    bike: Bike;
}>(Types.SET_BIKE);
export const setBikes = createAction<{
    bikeGroupId: string;
    bikes: Bike[];
}>(Types.SET_BIKES);
export const removeBike = createAction<{
    bikeGroupId: string;
    bikeId: string;
}>(Types.REMOVE_BIKE);

export const Actions = {
    [Types.SET_BIKE_GROUP]: setBikeGroup,
    [Types.SET_BIKE_GROUPS]: setBikeGroups,
    [Types.REMOVE_BIKE_GROUP]: removeBikeGroup,
    [Types.SET_BIKE]: setBike,
    [Types.SET_BIKES]: setBikes,
    [Types.REMOVE_BIKE]: removeBike,
};
export type Actions = typeof Actions;

export const getBikeGroups = wrapper(BikesAPI.getBikeGroups, (dispatch, bikeGroups) => {
    dispatch(setBikeGroups(bikeGroups));
});

export const getBikeGroup = wrapper(BikesAPI.getBikeGroup, (dispatch, bikeGroup) => {
    dispatch(setBikeGroup(bikeGroup));
});

export const createBikeGroup = wrapper(BikesAPI.createBikeGroup, (dispatch, bikeGroup) => {
    dispatch(setBikeGroup(bikeGroup));
});

export const updateBikeGroup = wrapper(BikesAPI.updateBikeGroup, (dispatch, bikeGroup) => {
    dispatch(setBikeGroup(bikeGroup));
});

export const deleteBikeGroup = wrapper(BikesAPI.deleteBikeGroup, (dispatch, bool, getState, bikeGroupId) => {
    dispatch(removeBikeGroup(bikeGroupId));
});

// Bikes
export const getBikes = wrapper(BikesAPI.getBikes, (dispatch, bikes, getState, bikeGroupId) => {
    dispatch(
        setBikes({
            bikeGroupId,
            bikes,
        }),
    );
});

export const getBike = wrapper(BikesAPI.getBike, (dispatch, bike, getState, bikeId, bikeGroupId) => {
    dispatch(
        setBike({
            bikeGroupId,
            bike,
        }),
    );
});

export const createBike = wrapper(BikesAPI.createBike, (dispatch, bike, getState, bikeGroupId) => {
    dispatch(
        setBike({
            bikeGroupId,
            bike,
        }),
    );
});

export const updateBike = wrapper(BikesAPI.updateBike, (dispatch, bike) => {
    dispatch(
        setBike({
            bikeGroupId: bike.bikeGroup,
            bike,
        }),
    );
});

export const deleteBike = wrapper(BikesAPI.deleteBike, (dispatch, bool, getState, bikeId, bikeGroupId) => {
    dispatch(
        removeBike({
            bikeGroupId,
            bikeId: bikeGroupId,
        }),
    );
});
