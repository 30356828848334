/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Review from "../../../types/model/Review";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getReviews(): Promise<Review[]> {
    return get(`/v1/reviews`);
}

function createReview(review: Omit<Review, "id" | "tour" | "status">): Promise<Review> {
    return post(`/v1/reviews`, review);
}

function updateReview(reviewId: string, review: Omit<Review, "id" | "tour">): Promise<Review> {
    return put(`/v1/reviews/${reviewId}`, review);
}

const ReviewsAPI = {
    getReviews,
    createReview,
    updateReview,
};

export default ReviewsAPI;
