/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 19/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import { DateTime } from "luxon";
import { store } from "../components/App";

export function currency<T = any>(key: keyof T) {
    return (rowData: T): string => {
        return Dinero({ currency: "NZD", amount: Number(rowData[key]) || 0 }).toFormat();
    };
}

export function date<T = any>(key: keyof T) {
    return (rowData: T): string => {
        return DateTime.fromISO(String(rowData[key])).toFormat("dd MMM yyyy");
    };
}

export function bikeGroupFormatter<T = any>(rowData: T & { bikeGroup: string }) {
    const { bikeGroup } = rowData;
    const { name } = store.getState().bikeGroups[bikeGroup] || {};
    return name;
}
