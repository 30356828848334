/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import { useField, useFormikContext } from "formik";
import React from "react";

export type CheckboxProps = {
    name: string;
    label?: string;
    labelProps?: Omit<FormControlLabelProps, "control" | "label">;
} & Omit<MuiCheckboxProps, "name" | "value" | "error">;

export default function CheckBox(props: CheckboxProps) {
    const { name, label, labelProps, ...otherProps } = props;

    const { isSubmitting } = useFormikContext();
    const [field] = useField(name);
    const disabled = props.disabled ?? isSubmitting;

    const checked = field.value || false;
    const value = checked ? "checked" : "";

    return (
        <FormControlLabel
            {...(labelProps || {})}
            control={<MuiCheckbox {...otherProps} {...field} checked={checked} value={value} />}
            label={label}
            disabled={disabled}
        />
    );
}
