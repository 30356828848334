/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum BookingType {
    TOUR_GUIDED = "TOUR_GUIDED",
    TOUR_SELF_GUIDED = "TOUR_SELF_GUIDED",
    CUSTOM_TOUR_GUIDED = "CUSTOM_TOUR_GUIDED",
    CUSTOM_TOUR_SELF_GUIDED = "CUSTOM_TOUR_SELF_GUIDED",
    HIRE = "HIRE",
}

export const TOUR_BOOKING_TYPES = [
    BookingType.TOUR_GUIDED,
    BookingType.TOUR_SELF_GUIDED,
];

export const CUSTOM_TOUR_BOOKING_TYPES = [
    BookingType.CUSTOM_TOUR_GUIDED,
    BookingType.CUSTOM_TOUR_SELF_GUIDED,
];

export const GUIDED_TOUR_BOOKING_TYPES = [
    BookingType.TOUR_GUIDED,
    BookingType.CUSTOM_TOUR_GUIDED,
];

export const SELF_GUIDED_TOUR_BOOKING_TYPES = [
    BookingType.TOUR_SELF_GUIDED,
    BookingType.CUSTOM_TOUR_SELF_GUIDED,
];

export enum BookingStatus {
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export default interface Booking {
    id: string;
    // user: string;
    name: string;
    email: string;
    description: string;
    phone: string;
    address: string;
    depositPaid: boolean;
    amount: number;
    depositAmount: number;
    amountPaid: number;
    discountAmount: number;
    discountPercentage: number;
    surchargeAmount: number;
    surchargePercentage: number;
    startDate: string;
    endDate: string;

    tour: string;
    tourDate: string;

    type: BookingType;
    status: BookingStatus;
};;;;;;;;;;
