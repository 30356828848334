/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { DateTime } from "luxon";
import MaterialTable, { Column } from "material-table";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { createReview, getReviews, updateReview } from "../../../../../redux/actions/reviews";
import Review, { ReviewStatus } from "../../../../../types/model/Review";
import { date } from "../../../../../util/columnFormatters";
import DateInput from "../../../../widgets/dateInput/DateInput";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import FormRow from "../../../../widgets/formRow/FormRow";
import Input from "../../../../widgets/input/Input";
import Spinner from "../../../../widgets/spinner/Spinner";
import styles from "../../Dashboard.module.scss";

const columns: Column<Review>[] = [
    {
        title: "Name",
        field: "name",
    },
    {
        title: "Review",
        field: "review",
    },
    {
        title: "Date",
        field: "date",
        render: date("date"),
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = {} & Omit<Review, "id">;

const validationSchema = Yup.object<FormValues>({
    name: Yup.string().required("Required"),
    review: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    status: Yup.mixed()
        .oneOf(Object.values(ReviewStatus))
        .required("Required"),
});

const TODAY = DateTime.local().toISO();

export default function Reviews() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [selectedReviewId, setSelectedReviewId] = useState<string | null>(null);
    const reviews = useSelector((state) => state.reviews);

    useMountEffect(async () => {
        await dispatch(getReviews());
        setLoading(false);
    });

    const dialogRef = useRef<DialogRef>(null);
    const onCreatePress = () => {
        dialogRef.current?.show();
    };

    const onRowPress = (e?: React.MouseEvent, review?: Review) => {
        setSelectedReviewId(review!.id);
        dialogRef.current?.show();
    };

    const onClose = () => {
        setSelectedReviewId(null);
    };

    const selectedReview = reviews[selectedReviewId || ""];

    return (
        <div className={styles.container}>
            <MaterialTable<Review>
                title={"Reviews"}
                columns={columns}
                data={Object.values(reviews)}
                onRowClick={onRowPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create Review",
                        isFreeAction: true,
                        onClick: onCreatePress,
                    },
                ]}
            />
            {loading && <Spinner overlay />}

            <Dialog<FormValues>
                dialogRef={dialogRef}
                createAction={async (values) => dispatch(createReview(values))}
                updateAction={async (values) => dispatch(updateReview(selectedReviewId!, values))}
                onClose={onClose}
                updating={!!selectedReviewId}
                title={`${selectedReviewId ? "Update" : "Create"} Review`}
                validationSchema={validationSchema}
                initialValues={
                    selectedReview
                        ? {
                            ...selectedReview,
                            date: DateTime.fromISO(selectedReview.date || TODAY).toFormat("yyyy-MM-dd"),
                        }
                        : {
                            name: "",
                            review: "",
                            date: DateTime.local().toFormat("yyyy-MM-dd"),
                            status: ReviewStatus.ACTIVE,
                        }
                }>
                <FormRow fullWidth>
                    <DateInput
                        name={"date"}
                        label={"Date"} />
                </FormRow>
                <FormRow fullWidth>
                    <Input
                        name={"status"}
                        label={"Status"}
                        options={[
                            {
                                label: "Active",
                                value: ReviewStatus.ACTIVE,
                            },
                            {
                                label: "Deleted",
                                value: ReviewStatus.DELETED,
                            },
                        ]}
                    />
                </FormRow>
                <FormRow fullWidth>
                    <Input
                        name={"name"}
                        label={"Name"} />
                </FormRow>
                <FormRow fullWidth>
                    <Input
                        name={"review"}
                        label={"Review"} />
                </FormRow>
            </Dialog>
        </div>
    );
}
