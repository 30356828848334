/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 4/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { useEffect } from "react";

export default function useMountEffect(effect: () => void, onError?: (err: any) => void) {
    useEffect(() => {
        try {
            const asyncFunc = async () => {
                await effect();
            };

            asyncFunc();
        } catch (err) {
            onError?.(err);
        }
    }, []);
}
