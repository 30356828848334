import LuxonUtils from '@date-io/luxon';
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import VercelAPI from "../redux/actions/api/vercel";
import configureStore from "../redux/store/configureStore";
import theme from "../styles/theme";
import Main from "./Main";
import SnackbarContainer from "./widgets/snackbar/SnackbarContainer";
import Spinner from "./widgets/spinner/Spinner";

export const { store, persistor } = configureStore();

const muiTheme = createMuiTheme(theme);

export default function App() {
    return (
        <Provider store={store}>
            <StylesProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <PersistGate loading={<Spinner center />} persistor={persistor}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <SnackbarContainer>
                                <Suspense fallback={<Spinner center />}>
                                    <Main />
                                </Suspense>
                            </SnackbarContainer>
                        </MuiPickersUtilsProvider>
                    </PersistGate>
                </ThemeProvider>
            </StylesProvider>
        </Provider>
    );
}
