/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/10/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import qsLib from "qs";

const qs = {
    stringify: (params: any): string => qsLib.stringify(params, { arrayFormat: "comma" }),
    parse: (search: string): any => qsLib.parse(search),
};

export default qs;
