/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import BikeBooking from "../../../types/model/BikeBooking";
import Booking from "../../../types/model/Booking";
import get from "./util/get";
import put from "./util/put";

function getBikeBookings(bookingId: string): Promise<BikeBooking[]> {
    return get(`/v1/bookings/${bookingId}/bike-bookings`);
}

function updateBikeBookings(bookingId: string, data: Omit<BikeBooking, "id" | "bike" | "booking" | "tour" | "tourDate" | "startDate" | "endDate">[]): Promise<{
    booking: Booking;
    bikeBookings: BikeBooking[];
}> {
    return put(`/v1/bookings/${bookingId}/bike-bookings`, data);
}

const BikeBookingsAPI = {
    getBikeBookings,
    updateBikeBookings,
};

export default BikeBookingsAPI;
