/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Tour from "../../../types/model/Tour";
import TourItineraryItem from "../../../types/model/TourItineraryItem";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getTours(): Promise<Tour[]> {
    return get("/v1/tours");
}

function getTour(tourId: string): Promise<Tour> {
    return get(`/v1/tours/${tourId}`);
}

function createTour(data: Pick<Tour, "title">): Promise<Tour> {
    return post("/v1/tours", data);
}

function updateTour(tourId: string, data: Omit<Tour, "id">): Promise<Tour> {
    return put(`/v1/tours/${tourId}`, data);
}

function deleteTour(tourId: string): Promise<boolean> {
    return del(`/v1/tours/${tourId}`);
}

function getTourItinerary(tourId: string): Promise<TourItineraryItem[]> {
    return get(`/v1/tours/${tourId}/itinerary`);
}

function updateTourItinerary(
    tourId: string,
    data: Omit<TourItineraryItem, "id" | "ordinal" | "tour">[],
): Promise<TourItineraryItem[]> {
    return put(`/v1/tours/${tourId}/itinerary`, data);
}

const ToursAPI = {
    getTours,
    getTour,
    createTour,
    updateTour,
    deleteTour,
    getTourItinerary,
    updateTourItinerary,
};

export default ToursAPI;
