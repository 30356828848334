/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2/12/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../../types/model/User";
import get from "./util/get";

function getUser(): Promise<User> {
    return get("/v1/me");
}

const UserAPI = {
    getUser,
};

export default UserAPI;
