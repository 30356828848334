/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getBike } from "../../../../../redux/actions/bikes";
import Spinner from "../../../../widgets/spinner/Spinner";
import BikeDetailsCard from "./components/bikeDetailsCard/BikeDetailsCard";

type Props = {} & RouteComponentProps<{
    bikeGroupId: string;
    bikeId: string;
}>;

export default function Bike(props: Props) {
    const { match } = props;
    const { params } = match;
    const { bikeGroupId, bikeId } = params;
    const bike = useSelector((state) => state.bikes[bikeGroupId]?.[bikeId]);
    const hasBike = !!bike;

    const [loading, setLoading] = useState(!hasBike);
    const dispatch = useDispatch();
    useMountEffect(async () => {
        if (!hasBike) {
            await dispatch(getBike(bikeId, bikeGroupId));
            setLoading(false);
        }
    });

    return (
        <>
            <BikeDetailsCard bikeGroupId={bikeGroupId} bikeId={bikeId} />
            {loading && <Spinner fullscreen />}
        </>
    );
}
