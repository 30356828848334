/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum BikeBookingType {
    TOUR_GUIDED = "TOUR_GUIDED",
    TOUR_GUIDED_BYOB = "TOUR_GUIDED_BYOB",
    TOUR_SELF_GUIDED = "TOUR_SELF_GUIDED",
    TOUR_SELF_GUIDED_BYOB = "TOUR_SELF_GUIDED_BYOB",
    CUSTOM_TOUR_GUIDED = "CUSTOM_TOUR_GUIDED",
    CUSTOM_TOUR_GUIDED_BYOB = "CUSTOM_TOUR_GUIDED_BYOB",
    CUSTOM_TOUR_SELF_GUIDED = "CUSTOM_TOUR_SELF_GUIDED",
    CUSTOM_TOUR_SELF_GUIDED_BYOB = "CUSTOM_TOUR_SELF_GUIDED_BYOB",
    BIKE_HIRE = "BIKE_HIRE",
}

export const BIKE_BOOKING_TOUR_TYPES = [
    BikeBookingType.TOUR_GUIDED,
    BikeBookingType.TOUR_GUIDED_BYOB,
    BikeBookingType.TOUR_SELF_GUIDED,
    BikeBookingType.TOUR_SELF_GUIDED_BYOB,
    BikeBookingType.CUSTOM_TOUR_GUIDED,
    BikeBookingType.CUSTOM_TOUR_GUIDED_BYOB,
    BikeBookingType.CUSTOM_TOUR_SELF_GUIDED,
    BikeBookingType.CUSTOM_TOUR_SELF_GUIDED_BYOB,
];

export const CUSTOM_BIKE_BOOKING_TOUR_TYPES = [
    BikeBookingType.CUSTOM_TOUR_GUIDED,
    BikeBookingType.CUSTOM_TOUR_GUIDED_BYOB,
    BikeBookingType.CUSTOM_TOUR_SELF_GUIDED,
    BikeBookingType.CUSTOM_TOUR_SELF_GUIDED_BYOB,
];

export const BYOB_BIKE_BOOKING_TOUR_TYPES = [
    BikeBookingType.TOUR_GUIDED_BYOB,
    BikeBookingType.TOUR_SELF_GUIDED_BYOB,
    BikeBookingType.CUSTOM_TOUR_GUIDED_BYOB,
    BikeBookingType.CUSTOM_TOUR_SELF_GUIDED_BYOB,
];

export enum BikeBookingStatus {
    ACTIVE = "ACTIVE",
    DRAFT = "DRAFT",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export default interface BikeBooking {
    id: string;
    name: string;
    bikeGroup: string;
    bike: string;
    tour: string;
    tourDate: string;
    booking: string;
    // user: string;
    startDate: string;
    endDate: string;
    hasPassenger: boolean;
    hasSingleRoom: boolean;
    riderAmount: number;
    passengerAmount: number;
    singleRoomAmount: number;

    type: BikeBookingType;
    status: BikeBookingStatus;
};
