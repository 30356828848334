/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./FormRow.module.scss";

export type FormRowProps = PropsWithChildren<{
    className?: string;
    fullWidth?: boolean;
}>;

export default function FormRow(props: FormRowProps) {
    const { children, fullWidth } = props;
    const className = classNames(
        styles.form_row,
        {
            [styles.fullWidth]: fullWidth,
        },
        props.className,
    );
    return <div className={className}>{children}</div>;
}
