/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

const Loading = {
    root: "*",
};

const Tour = {
    root: "/tours/:tourId",
};

const Tours = {
    root: "/tours",
    Tour,
};

const Bike = {
    root: "/bike-groups/:bikeGroupId/bikes/:bikeId",
};

const BikeGroup = {
    root: "/bike-groups/:bikeGroupId",
    Bike,
};

const BikeGroups = {
    root: "/bike-groups",
    BikeGroup,
};

const Reviews = {
    root: "/reviews",
};

const Booking = {
    root: "/bookings/:bookingId",
};

const Bookings = {
    root: "/bookings",
    Booking,
};

const Routes = {
    Loading,
    Tours,
    BikeGroups,
    Reviews,
    Bookings,
};

export default Routes;
