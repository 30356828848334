/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import TourDate from "../../types/model/TourDate";
import TourDatesAPI from "./api/tourDates";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_TOUR_DATE = "tourDates:SET_TOUR_DATE",
    SET_TOUR_DATES = "tourDates:SET_TOUR_DATES",
    REMOVE_TOUR_DATE = "tourDates:REMOVE_TOUR_DATE",
}

export const setTourDate = createAction<{ tourId: string; tourDate: TourDate }>(Types.SET_TOUR_DATE);
export const setTourDates = createAction<{ tourId: string; tourDates: TourDate[] }>(Types.SET_TOUR_DATES);
export const removeTourDate = createAction<{ tourId: string; tourDateId: string }>(Types.REMOVE_TOUR_DATE);

export const Actions = {
    [Types.SET_TOUR_DATE]: setTourDate,
    [Types.SET_TOUR_DATES]: setTourDates,
    [Types.REMOVE_TOUR_DATE]: removeTourDate,
};
export type Actions = typeof Actions;

export const getTourDates = wrapper(TourDatesAPI.getTourDates, (dispatch, tourDates, getState, tourId) => {
    dispatch(
        setTourDates({
            tourId,
            tourDates,
        }),
    );
});

export const createTourDate = wrapper(TourDatesAPI.createTourDate, (dispatch, tourDate, getState, tourId) => {
    dispatch(
        setTourDate({
            tourId,
            tourDate,
        }),
    );
});

export const updateTourDate = wrapper(TourDatesAPI.updateTourDate, (dispatch, tourDate) => {
    dispatch(
        setTourDate({
            tourId: tourDate.tour,
            tourDate,
        }),
    );
});

export const deleteTourDate = wrapper(TourDatesAPI.deleteTourDate, (dispatch, bool, getState, tourDateId, tourId) => {
    dispatch(
        removeTourDate({
            tourId,
            tourDateId,
        }),
    );
});
