/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 23/10/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import bikeBookings from "./bikeBookings";
import bikeGroups from "./bikeGroups";
import bikes from "./bikes";
import bookings from "./bookings";
import reviews from "./reviews";
import snackbars from "./snackbars";
import tourDates from "./tourDates";
import tourItinerary from "./tourItinerary";
import tours from "./tours";
import user from "./user";

const reducers = {
    bikeBookings,
    bikeGroups,
    bikes,
    bookings,
    reviews,
    snackbars,
    tourDates,
    tourItinerary,
    tours,
    user,
};

const PERSIST_CONFIG: PersistConfig<any> = {
    key: "root",
    storage,
    whitelist: ["settings"],
};

const root = combineReducers(reducers);
const persistedReducer = persistReducer(PERSIST_CONFIG, root);

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
