/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../redux/reducers/root";
import useDispatch from "./useDispatch";

type ThunkActions = {
    [key: string]: ThunkAction<Promise<any>, ApplicationState, any, AnyAction>;
};

type UnboxPromise<T extends Promise<any>> = T extends Promise<infer U> ? U : never;

type Values<T extends ThunkActions> = UnboxPromise<ReturnType<T[keyof T]>>;
type ValueOf<T> = T[keyof T];

export default function useAsyncCall() {
    const dispatch = useDispatch();

    function callAsync<T extends ThunkActions>(actions: T) {
        type Returns = Values<T>;

        const dispatchedActions = Object.values(actions).map((action) => {
            return dispatch(action);
        });

        return Promise.all(dispatchedActions) as Returns;
    }

    return callAsync;
}
