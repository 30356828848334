/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Bike from "../../types/model/Bike";
import { Actions, Types } from "../actions/bikes";

const initialState: {
    [key: string]: {
        [key: string]: Bike;
    };
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function bikes(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BIKE: {
            const payload = action.payload as ActionValueTypes<Types.SET_BIKE>;
            const { bikeGroupId, bike } = payload!;

            return {
                ...state,
                [bikeGroupId]: {
                    ...(state[bikeGroupId] || {}),
                    [bike.id]: bike,
                },
            };
        }
        case Types.SET_BIKES: {
            const payload = action.payload as ActionValueTypes<Types.SET_BIKES>;
            const { bikeGroupId, bikes: bikesList } = payload!;

            const newState = {
                ...state,
            };

            bikesList.forEach((bike) => {
                newState[bikeGroupId] = {
                    ...(newState[bikeGroupId] || {}),
                    [bike.id]: bike,
                };
            });

            return newState;
        }

        case Types.REMOVE_BIKE: {
            const payload = action.payload as ActionValueTypes<Types.REMOVE_BIKE>;
            const { bikeGroupId, bikeId } = payload!;

            const bikeGroup = {
                ...(state[bikeGroupId] || {}),
            };

            delete bikeGroup[bikeId];

            return {
                ...state,
                [bikeGroupId]: bikeGroup,
            };
        }
        default: {
            return state;
        }
    }
}
