/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getBooking } from "../../../../../redux/actions/bookings";
import Spinner from "../../../../widgets/spinner/Spinner";
import BikeBookings from "./components/bikeBookings/BikeBookings";
import BookingDetailsCard from "./components/bookingDetailsCard/BookingDetailsCard";

type Props = {} & RouteComponentProps<{
    bookingId: string;
}>;
export default function Booking(props: Props) {
    const { match } = props;
    const { params } = match;
    const { bookingId } = params;

    const booking = useSelector((state) => state.bookings[bookingId]);
    const hasBooking = !!booking;

    const [loading, setLoading] = useState(!hasBooking);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        if (!hasBooking) {
            await dispatch(getBooking(bookingId));
            setLoading(false);
        }
    });

    return (
        <>
            <BookingDetailsCard bookingId={bookingId} />
            {booking && <BikeBookings bookingId={bookingId} />}
            {loading && <Spinner fullscreen />}
        </>
    );
}
