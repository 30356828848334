/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 21/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum ReviewStatus {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}

export default interface Review {
    id: string;
    name: string;
    review: string;
    // tour: string;
    // booking: string;
    // rating: string;
    date: string;
    status: ReviewStatus;
}
