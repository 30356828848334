/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Tour from "../../types/model/Tour";
import { Actions, Types } from "../actions/tours";

const initialState: {
    [key: string]: Tour;
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function tours(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_TOUR: {
            const payload = action.payload as ActionValueTypes<Types.SET_TOUR>;
            const tour = payload!;

            return {
                ...state,
                [tour.id]: tour,
            };
        }
        case Types.SET_TOURS: {
            const payload = action.payload as ActionValueTypes<Types.SET_TOURS>;
            const toursList = payload!;

            const newState = {
                ...state,
            };

            toursList.forEach((tour) => {
                newState[tour.id] = tour;
            });

            return newState;
        }

        case Types.REMOVE_TOUR: {
            const payload = action.payload as ActionValueTypes<Types.REMOVE_TOUR>;
            const tourId = payload!;

            const newState = {
                ...state,
            };

            delete newState[tourId];

            return newState;
        }
        default: {
            return state;
        }
    }
}
