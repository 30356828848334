/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";
import { Action } from "../actions/helpers/createAction";
import { Types } from "../actions/snackbars";

const initialState: SnackbarConfig[] = [];

export default function snackbars(
    state = initialState,
    action: Action<any> = {
        type: null,
        payload: null,
    },
): SnackbarConfig[] {
    switch (action.type) {
        case Types.ADD_SNACKBAR: {
            const config = action.payload;
            if (!config) {
                return state;
            }

            return [...state, config];
        }

        case Types.REMOVE_SNACKBAR: {
            return [...state].filter((snackbar) => {
                return snackbar.id !== action.payload;
            });
        }
        default: {
            return state;
        }
    }
}
