/* eslint-disable react/no-array-index-key */
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 31/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { ExpansionPanel } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ArrayHelpers, FieldArray, Form, Formik } from "formik";
import React, { ReactElement, useState } from "react";
import ReactQuill from "react-quill";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { getTourItinerary, updateTourItinerary } from "../../../../../../../redux/actions/tours";
import TourItineraryItem from "../../../../../../../types/model/TourItineraryItem";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import CheckBox from "../../../../../../widgets/checkBox/CheckBox";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Gallery from "../../../../../../widgets/gallery/Gallery";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./TourItineraryItems.module.scss";
import sortBy from "lodash/sortBy";

type Props = {
    tourId: string;
};

type TourItineraryItemDTO = Omit<TourItineraryItem, "id" | "ordinal" | "tour">;

type TourItineraryItemsFormValues = {
    items: TourItineraryItemDTO[];
};

const INSERT_NEW_MODEL: TourItineraryItemDTO = {
    from: "",
    to: "",
    description: "",
    highlight: "",
    breakfastIncl: false,
    lunchIncl: false,
    dinnerIncl: false,
};

export default function TourItineraryItems(props: Props): ReactElement {
    const { tourId } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();
    const tourItinerary = useSelector((state) => sortBy((state.tourItinerary[tourId] || []), "ordinal"));

    useMountEffect(
        async () => {
            await dispatch(getTourItinerary(tourId));
            setLoading(false);
        },
        (err) => {
            setError(err.message);
            setLoading(false);
        },
    );

    const onSaveTourItinerarySubmit = async ({ items }: TourItineraryItemsFormValues) => {
        try {
            setError(null);
            setLoading(true);
            await dispatch(updateTourItinerary(tourId, items));
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    return (
        <Card className={styles.tour_itinerary_items}>
            <Formik<TourItineraryItemsFormValues>
                enableReinitialize
                initialValues={{
                    items: tourItinerary,
                }}
                onSubmit={onSaveTourItinerarySubmit}>
                {({ isValid, dirty, values: { items }, setFieldValue, isSubmitting }) => (
                    <Form>
                        <CardHeader
                            title={"Tour Itinerary:"}
                            action={(
                                <Button type={"submit"} disabled={!isValid || !dirty}>
                                    Save
                                </Button>
                              )}
                        />
                        <ErrorBlock error={error} />

                        <FieldArray name={"items"}>
                            {(arrayHelpers: ArrayHelpers) => (
                                <>
                                    {items.map((itinerary, index) => (
                                        <ExpansionPanel key={index} className={styles.exp_panel} variant={"outlined"}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant={"h6"}>
                                                    Day {index + 1}: {itinerary.from ? `${itinerary.from} to ${itinerary.to}` : ""}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className={styles.exp_panel_content}>
                                                <div className={styles.ite_fields}>
                                                    <div className={styles.details}>
                                                        <FormRow>
                                                            <Input name={`items[${index}].from`} label={"From"} />
                                                            <Input name={`items[${index}].to`} label={"To"} />
                                                        </FormRow>
                                                        <FormRow>
                                                            <Input name={`items[${index}].highlight`} label={"Highlight"} />
                                                        </FormRow>
                                                        <div className={styles.checkboxes}>
                                                            <CheckBox
                                                                label={"Breakfast Incl"}
                                                                name={`items[${index}].breakfastIncl`}
                                                            />
                                                            <CheckBox label={"Lunch Incl"} name={`items[${index}].lunchIncl`} />
                                                            <CheckBox label={"Dinner Incl"} name={`items[${index}].dinnerIncl`} />
                                                        </div>
                                                    </div>
                                                    <ReactQuill value={items[index].description} onChange={(value) => setFieldValue(`items[${index}].description`, value)} />
                                                    <Gallery name={`items[${index}].images`} />
                                                    <Button small onClick={() => arrayHelpers.remove(index)}>
                                                        Remove Leg
                                                    </Button>
                                                </div>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))}

                                    <Button onClick={() => arrayHelpers.insert(items.length, INSERT_NEW_MODEL)}>Add Leg</Button>
                                </>
                            )}
                        </FieldArray>
                        {(isSubmitting || loading) && <Spinner overlay />}
                    </Form>
                )}
            </Formik>
        </Card>
    );
}
