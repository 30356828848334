/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 6/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/model/User";
import UserAPI from "./api/user";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_USER = "user:SET_USER",
    REMOVE_USER = "user:REMOVE_USER",
}

export const setUser = createAction<User>(Types.SET_USER);
export const removeUser = createAction<null>(Types.REMOVE_USER);

export const Actions = {
    [Types.SET_USER]: setUser,
    [Types.REMOVE_USER]: removeUser,
};
export type Actions = typeof Actions;

export const getUser = wrapper(UserAPI.getUser, (dispatch, user) => {
    dispatch(setUser(user));
});
