/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Review from "../../types/model/Review";
import ReviewsAPI from "./api/reviews";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_REVIEW = "reviews:SET_REVIEW",
    SET_REVIEWS = "reviews:SET_REVIEWS",
    REMOVE_REVIEW = "reviews:REMOVE_REVIEW",
}

export const setReview = createAction<Review>(Types.SET_REVIEW);
export const setReviews = createAction<Review[]>(Types.SET_REVIEWS);
export const removeReview = createAction<string>(Types.REMOVE_REVIEW);

export const Actions = {
    [Types.SET_REVIEW]: setReview,
    [Types.SET_REVIEWS]: setReviews,
    [Types.REMOVE_REVIEW]: removeReview,
};
export type Actions = typeof Actions;

export const getReviews = wrapper(ReviewsAPI.getReviews, (dispatch, reviews) => {
    dispatch(setReviews(reviews));
});

export const createReview = wrapper(ReviewsAPI.createReview, (dispatch, review) => {
    dispatch(setReview(review));
});

export const updateReview = wrapper(ReviewsAPI.updateReview, (dispatch, review) => {
    dispatch(setReview(review));
});
