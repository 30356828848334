/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { ReactElement, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getTour } from "../../../../../redux/actions/tours";
import Spinner from "../../../../widgets/spinner/Spinner";
import TourDatesCard from "./components/tourDatesCard/TourDatesCard";
import TourDetailsCard from "./components/tourDetailsCard/TourDetailsCard";
import TourItineraryItems from "./components/tourItineraryItems/TourItineraryItems";
import "./Tour.module.scss";

type Props = {} & RouteComponentProps<{
    tourId: string;
}>;

export default function Tour(props: Props): ReactElement {
    const { match } = props;
    const { params } = match;
    const { tourId } = params;
    const tour = useSelector((state) => state.tours[tourId]);
    const hasTour = !!tour;

    const [loading, setLoading] = useState(!hasTour);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        if (!hasTour) {
            await dispatch(getTour(tourId));
            setLoading(false);
        }
    });

    return (
        <>
            <TourDetailsCard tourId={tourId} />
            <TourItineraryItems tourId={tourId} />
            <TourDatesCard tourId={tourId} />
            {loading && <Spinner fullscreen />}
        </>
    );
}
