/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-05-26.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import MatButton, { ButtonProps as MatButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import React, { ReactElement, Ref } from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export type ButtonProps = {
    loading?: boolean;
    plain?: boolean;
    red?: boolean;
    secondary?: boolean;
    small?: boolean;
} & MatButtonProps;

function Button(props: ButtonProps, ref: Ref<unknown>): ReactElement {
    const { loading, plain, red, secondary, small, endIcon, variant, disabled, ...otherProps } = props;

    const className = classNames(
        styles.button,
        {
            [styles.green]: !plain,
            [styles.red]: red,
            [styles.plain]: plain,
            [styles.disabled]: disabled,
        },
        props.className,
    );

    let size: "small" | "medium" | "large" | undefined;
    if (small) {
        size = "small";
    }

    return (
        <MatButton
            {...otherProps}
            disabled={disabled}
            className={className}
            size={size}
            variant={variant || "contained"}
            buttonRef={ref}
            endIcon={loading ? <Spinner small white /> : endIcon}
        />
    );
}

export default React.forwardRef(Button);
