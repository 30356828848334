/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import { DateTime } from "luxon";
import MaterialTable, { Column } from "material-table";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { createTourDate, getTourDates, updateTourDate } from "../../../../../../../redux/actions/tourDates";
import TourDate, { TourDateStatus } from "../../../../../../../types/model/TourDate";
import { currency, date } from "../../../../../../../util/columnFormatters";
import Dialog, { DialogRef } from "../../../../../../widgets/dialog/Dialog";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./TourDatesCard.module.scss";

type Props = {
    tourId: string;
};

const columns: Column<TourDate>[] = [
    {
        title: "Start Date",
        field: "startDate",
        render: date("startDate"),
    },
    {
        title: "End Date",
        field: "endDate",
        render: date("endDate"),
    },
    {
        title: "BYOB Price",
        field: "priceBYOB",
        render: currency("priceBYOB"),
    },
    {
        title: "Passenger Price",
        field: "pricePassenger",
        render: currency("pricePassenger"),
    },
    {
        title: "Single Room Price",
        field: "priceSingleRoom",
        render: currency("priceSingleRoom"),
    },
    {
        title: "Avg Bike Price",
        render: (rowData) => {
            const prices = Object.values(rowData.prices || {});
            const averagePrice = Math.round(prices.reduce((currentPrice, price) => currentPrice + price, 0) / prices.length);
            return Dinero({ currency: "NZD", amount: averagePrice || 0 }).toFormat();
        },
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Omit<TourDate, "id" | "tour">;

const validationSchema = Yup.object<FormValues>({
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
    priceBYOB: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    pricePassenger: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    priceSingleRoom: Yup.number()
        .required("Required")
        .min(0, "Must be greater than or equal to 0"),
    prices: Yup.object<TourDate["prices"]>()
        .required("Required"),
    status: Yup.mixed()
        .oneOf(Object.values(TourDateStatus))
        .required("Required"),
});

export default function TourDatesCard(props: Props) {

    const { tourId } = props;
    const tour = useSelector((state) => state.tours[tourId]);
    const tourDates = useSelector((state) => state.tourDates[tourId] || {});
    const bikeGroups = useSelector((state) => state.bikeGroups);

    const validation = {};
    const initialBikeGroupPriceValues: TourDate["prices"] = {};
    Object.values(bikeGroups).forEach((bikeGroup) => {
        validation[bikeGroup.id] = Yup.number()
            .required("Required")
            .min(0, "Must be greater than or equal to 0");
        initialBikeGroupPriceValues[bikeGroup.id] = 0;
    });

    const newValidationSchema = validationSchema.shape({
        prices: Yup.object<TourDate["prices"]>(validation).required("Required"),
    });

    const [loading, setLoading] = useState(true);
    const [selectedTourDateId, setSelectedTourDateId] = useState<string | null>(null);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        await dispatch(getTourDates(tourId));
        setLoading(false);
    });

    const dialogRef = useRef<DialogRef>(null);
    const onCreatePress = () => {
        dialogRef.current?.show();
    };

    const onRowPress = (e?: React.MouseEvent, tourDate?: TourDate) => {
        setSelectedTourDateId(tourDate!.id);
        dialogRef.current?.show();
    };

    const onClose = () => {
        setSelectedTourDateId(null);
    };

    const selectedTourDate = tourDates[selectedTourDateId || ""];

    return (
        <div className={styles.tour_dates_card}>
            <MaterialTable
                title={"Tour Dates"}
                columns={columns}
                data={Object.values(tourDates)}
                onRowClick={onRowPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create Tour Date",
                        isFreeAction: true,
                        onClick: onCreatePress,
                    },
                ]}
            />
            {loading && <Spinner overlay />}

            <Dialog<FormValues>
                dialogRef={dialogRef}
                createAction={async (values) => dispatch(createTourDate(tourId, values))}
                updateAction={async (values) => dispatch(updateTourDate(selectedTourDateId!, values))}
                onClose={onClose}
                updating={!!selectedTourDateId}
                title={`${selectedTourDateId ? "Update" : "Create"} Tour Date`}
                validationSchema={newValidationSchema}
                initialValues={
                    selectedTourDate
                        ? {
                            ...selectedTourDate,
                            startDate: DateTime.fromISO(selectedTourDate.startDate || DateTime.local().toISO()).toFormat(
                                "yyyy-MM-dd",
                            ),
                            endDate: DateTime.fromISO(selectedTourDate.endDate || DateTime.local().toISO()).toFormat("yyyy-MM-dd"),
                        }
                        : {
                            startDate: DateTime.local().toFormat("yyyy-MM-dd"),
                            endDate: DateTime.local()
                                .plus({ days: tour?.days })
                                .toFormat("yyyy-MM-dd"),
                            priceBYOB: 0,
                            pricePassenger: 0,
                            priceSingleRoom: 0,
                            prices: initialBikeGroupPriceValues,
                            status: TourDateStatus.ACTIVE,
                        }
                }>
                <FormRow fullWidth>
                    <Input name={"startDate"} label={"Start Date"} type={"date"} />
                    <Input name={"endDate"} label={"End Date"} type={"date"} />
                </FormRow>
                <FormRow fullWidth>
                    <Input
                        name={"status"}
                        label={"Status"}
                        options={[
                            {
                                label: "Active",
                                value: TourDateStatus.ACTIVE,
                            },
                            {
                                label: "Inactive",
                                value: TourDateStatus.INACTIVE,
                            },
                            {
                                label: "Full",
                                value: TourDateStatus.FULL,
                            },
                            {
                                label: "Cancelled",
                                value: TourDateStatus.CANCELLED,
                            },
                            {
                                label: "Deleted",
                                value: TourDateStatus.DELETED,
                            },
                        ]}
                    />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"priceBYOB"} label={"BYOB Price"} money />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"pricePassenger"} label={"Passenger Price"} money />
                </FormRow>
                <FormRow fullWidth>
                    <Input name={"priceSingleRoom"} label={"Single Room Price"} money />
                </FormRow>
                {Object.values(bikeGroups).map((bikeGroup) => (
                    <FormRow fullWidth key={bikeGroup.id}>
                        <Input name={`prices.${bikeGroup.id}`} label={`${bikeGroup.name} Price`} money />
                    </FormRow>
                ))}
            </Dialog>
        </div>
    );
}
