/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import MaterialTable, { Column } from "material-table";
import React, { ReactElement, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { createBike, getBikes } from "../../../../../../../redux/actions/bikes";
import Bike, { BikeStatus } from "../../../../../../../types/model/Bike";
import Button from "../../../../../../widgets/button/Button";
import DateInput from "../../../../../../widgets/dateInput/DateInput";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "../../../../Dashboard.module.scss";

type Props = {
    bikeGroupId: string;
};

const columns: Column<Bike>[] = [
    {
        title: "Number Plate",
        field: "numberPlate",
    },
    {
        title: "REGO Expiry",
        field: "regoExpiry",
        render: (rowData: Bike) => DateTime.fromISO(rowData.regoExpiry).toFormat("dd/MM/yyyy"),
    },
    {
        title: "COF Expiry",
        field: "cofExpiry",
        render: (rowData: Bike) => DateTime.fromISO(rowData.cofExpiry).toFormat("dd/MM/yyyy"),
    },
    {
        title: "Service Expiry",
        field: "serviceExpiry",
        render: (rowData: Bike) => DateTime.fromISO(rowData.serviceExpiry).toFormat("dd/MM/yyyy"),
    },
    {
        title: "Service Mileage",
        field: "serviceMileage",
    },
    {
        title: "Current Mileage",
        field: "currentMileage",
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Omit<Bike, "id" | "bikeGroup">;

const validationSchema = Yup.object<FormValues>({
    numberPlate: Yup.string().required("Required"),
    regoExpiry: Yup.string().required("Required"),
    cofExpiry: Yup.string().required("Required"),
    serviceExpiry: Yup.string().required("Required"),
    serviceMileage: Yup.number().required("Required"),
    currentMileage: Yup.number().required("Required"),
    status: Yup.mixed()
        .oneOf(Object.values(BikeStatus))
        .required("Required"),
});

export default function Bikes(props: Props): ReactElement {
    const { bikeGroupId } = props;
    const bikes = useSelector((state) => state.bikes[bikeGroupId] || []);

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [createDialogShown, setCreateDialogShown] = useState(false);

    useMountEffect(async () => {
        await dispatch(getBikes(bikeGroupId));
        setLoading(false);
    });

    const onCreatePress = useCallback(() => {
        setCreateDialogShown(true);
    }, [setCreateDialogShown]);

    const onSubmit = useCallback(
        async (values: FormValues) => {
            try {
                const bike = await dispatch(createBike(bikeGroupId, values));
                history.push(`/bike-groups/${bikeGroupId}/bikes/${bike?.id}`);
            } catch (e) {
                setError(e.message);
            }
        },
        [dispatch, bikeGroupId, history],
    );

    const closeCreateDialog = useCallback(() => {
        setCreateDialogShown(false);
    }, [setCreateDialogShown]);

    const onRowPress = useCallback(
        (e?: React.MouseEvent, bike?: Bike) => {
            history.push(`/bike-groups/${bikeGroupId}/bikes/${bike!.id}`);
        },
        [history, bikeGroupId],
    );

    return (
        <div className={styles.container}>
            <MaterialTable<Bike>
                title={"Bikes"}
                columns={columns}
                data={Object.values(bikes)}
                onRowClick={onRowPress}
                options={{
                    search: false,
                }}
                actions={[
                    {
                        icon: "add",
                        tooltip: "Create Bike",
                        isFreeAction: true,
                        onClick: onCreatePress,
                    },
                ]}
            />

            {loading && <Spinner overlay />}

            <Formik<FormValues>
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{
                    numberPlate: "",
                    regoExpiry: DateTime.local().toFormat("yyyy-MM-dd"),
                    cofExpiry: DateTime.local().toFormat("yyyy-MM-dd"),
                    serviceExpiry: DateTime.local().toFormat("yyyy-MM-dd"),
                    serviceMileage: 0,
                    currentMileage: 0,
                    status: BikeStatus.INACTIVE,
                }}>
                {({ isValid, dirty, isSubmitting }) => (
                    <Dialog
                        open={createDialogShown}
                        onClose={closeCreateDialog}
                        disableBackdropClick={isSubmitting}
                        disableEscapeKeyDown={isSubmitting}>
                        <Form>
                            <DialogTitle>Create Bike</DialogTitle>
                            <DialogContent>
                                <FormRow fullWidth>
                                    <Input name={"numberPlate"} label={"Number Plate"} placeholder={"ABC123"} />
                                </FormRow>
                                <FormRow fullWidth>
                                    <DateInput name={"regoExpiry"} label={"REGO Expiry"} />
                                    <DateInput name={"cofExpiry"} label={"COF Expiry"} placeholder={"ABC123"} />
                                    <DateInput name={"serviceExpiry"} label={"Service Expiry"} placeholder={"ABC123"} />
                                </FormRow>
                                <FormRow fullWidth>
                                    <Input name={"serviceMileage"} label={"Service Mileage"} type={"number"} />
                                    <Input name={"currentMileage"} label={"Current Mileage"} type={"number"} />
                                </FormRow>
                                <ErrorBlock error={error} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeCreateDialog} plain disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <Button type={"submit"} disabled={!isValid || !dirty} loading={isSubmitting}>
                                    Create
                                </Button>
                            </DialogActions>
                        </Form>
                    </Dialog>
                )}
            </Formik>
        </div>
    );
}
