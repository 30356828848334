/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum TourDateStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    FULL = "FULL",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export default interface TourDate {
    id: string;
    tour: string;
    startDate: string;
    endDate: string;
    priceBYOB: number;
    pricePassenger: number;
    priceSingleRoom: number;
    prices: {
        [key: string]: number;
    }
    status: TourDateStatus;
}
