/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { Form, Formik, FormikProps } from "formik";
import { DateTime } from "luxon";
import React, { useState } from "react";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { updateBike } from "../../../../../../../redux/actions/bikes";
import Bike, { BikeStatus } from "../../../../../../../types/model/Bike";
import Nullable from "../../../../../../../types/Nullable";
import Button from "../../../../../../widgets/button/Button";
import DateInput from "../../../../../../widgets/dateInput/DateInput";
import ErrorBlock from "../../../../../../widgets/errorBlock/ErrorBlock";
import FormRow from "../../../../../../widgets/formRow/FormRow";
import Input from "../../../../../../widgets/input/Input";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "./BikeDetailsCard.module.scss";

type Props = {
    bikeGroupId: string;
    bikeId: string;
};

type FormValues = {} & Omit<Bike, "id">;

const TODAY = DateTime.local().toISO();

export default function BikeDetailsCard(props: Props) {
    const { bikeGroupId, bikeId } = props;

    const bike = useSelector((state) => state.bikes[bikeGroupId]?.[bikeId] || {});

    const [error, setError] = useState<Nullable<string>>(null);
    const dispatch = useDispatch();

    const onSubmit = async (values: FormValues) => {
        try {
            setError(null);
            await dispatch(updateBike(bikeId, values));
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Formik<FormValues>
            initialValues={{
                ...bike,
                regoExpiry: DateTime.fromISO(bike.regoExpiry || TODAY).toFormat("yyyy-MM-dd"),
                cofExpiry: DateTime.fromISO(bike.cofExpiry || TODAY).toFormat("yyyy-MM-dd"),
                serviceExpiry: DateTime.fromISO(bike.serviceExpiry || TODAY).toFormat("yyyy-MM-dd"),
            }}
            onSubmit={onSubmit}
            enableReinitialize>
            {({ isValid, dirty, isSubmitting }: FormikProps<FormValues>) => (
                <Card className={styles.bike_details_card}>
                    <Form>
                        <CardHeader
                            title={bike?.numberPlate || "Loading..."}
                            action={(
                                <Button type={"submit"} disabled={!isValid || !dirty}>
                                    Save
                                </Button>
                              )}
                        />
                        <FormRow>
                            <Input
                                name={"status"}
                                label={"Status"}
                                options={[
                                    {
                                        label: "Active",
                                        value: BikeStatus.ACTIVE,
                                    },
                                    {
                                        label: "Inactive",
                                        value: BikeStatus.INACTIVE,
                                    },
                                    {
                                        label: "Deleted",
                                        value: BikeStatus.DELETED,
                                    },
                                ]}
                            />
                            <div />
                        </FormRow>
                        <FormRow>
                            <Input name={"numberPlate"} label={"Number Plate"} placeholder={"ABC123"} />
                        </FormRow>
                        <FormRow>
                            <DateInput name={"regoExpiry"} label={"REGO Expiry"} />
                            <DateInput name={"cofExpiry"} label={"COF Expiry"} placeholder={"ABC123"}  />
                            <DateInput name={"serviceExpiry"} label={"Service Expiry"} placeholder={"ABC123"} />
                        </FormRow>
                        <FormRow>
                            <Input name={"serviceMileage"} label={"Service Mileage"} placeholder={"ABC123"} type={"number"} />
                            <Input name={"currentMileage"} label={"Current Mileage"} placeholder={"ABC123"} type={"number"} />
                        </FormRow>

                        <ErrorBlock error={error} />
                    </Form>
                    {isSubmitting && <Spinner overlay />}
                </Card>
            )}
        </Formik>
    );
}
