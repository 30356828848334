/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 27/01/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { ReactElement, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getBikeGroup } from "../../../../../redux/actions/bikes";
import Spinner from "../../../../widgets/spinner/Spinner";
import BikeGroupDetailsCard from "./components/bikeGroupDetailsCard/BikeGroupDetailsCard";
import Bikes from "./components/bikes/Bikes";

type Props = {} & RouteComponentProps<{
    bikeGroupId: string;
}>;

export default function BikeGroup(props: Props): ReactElement {
    const { match } = props;
    const { params } = match;
    const { bikeGroupId } = params;
    const bikeGroup = useSelector((state) => state.bikeGroups[bikeGroupId]);
    const hasBikeGroup = !!bikeGroup;

    const [loading, setLoading] = useState(!hasBikeGroup);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        if (!hasBikeGroup) {
            await dispatch(getBikeGroup(bikeGroupId));
            setLoading(false);
        }
    });

    return (
        <>
            <BikeGroupDetailsCard bikeGroupId={bikeGroupId} />
            <Bikes bikeGroupId={bikeGroupId} />
            {loading && <Spinner fullscreen />}
        </>
    );
}
